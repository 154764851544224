<template>
	<nav>
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<router-link :to="'/' + lang + query">
					<i class="fa fa-home"></i>
				</router-link>
			</li>
			<li
				v-for="item in items"
				:key="item.id"
				class="breadcrumb-item"
			>
				<span v-if="!item.current">
					<router-link :to="'/' + item.segment_name + lang + query">
						{{ item.title }}
					</router-link>
				</span>
				<span v-else>
					{{ item.title }}
				</span>
			</li>
		</ol>
	</nav>
</template>

<script>
export default {
	props: {
		items: Array
	},

	data() {
		return {
			query: '',
			lang: ''
		}
	},

	created() {
		for (let i = 0; i <= this.items.length; i ++) {
			if (this.items[i] != undefined) {
				this.items[i].current = ((i + 1) === this.items.length)? true : false;
			}
		}
	},

	mounted() {
		if (this.$route.query.mode == 'preview') {
			this.query = '?mode=preview';
		}
		if (this.$route.query.hl) {
			this.lang = '?hl=' + this.$route.query.hl;
		}
	}
}
</script>

<style lang="scss">
.breadcrumb {
	padding: .75rem 50px;
	background: none;

	.breadcrumb-item {
		font-size: 14px;
	}
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
	.breadcrumb {
		padding: .75rem 30px;
	}
}

/* SP Only */
@media (max-width: 767px) {
	.breadcrumb {
		padding: .75rem 20px;
	}
}
</style>