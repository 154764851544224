<template>
	<section class="iv2-page-header">
		<div
			v-if="items.header_type == 'add_catch'"
			class="iv2-page-header-type1 fit-img overflow-hidden"
		>
			<picture>
				<source
					type="image/webp"
					:srcset="items.header_image + publicLang.general.image_webp"
				>
				<img
					class="iv2-page-header__img"
					:src="items.header_image"
					:alt="items.title + publicLang.page.alt">
			</picture>
			<div class="iv2-page-header__cover theme-f__page-header-bg--with-catchcopy position-absolute"></div>
			<div class="iv2-page-header__title theme-f__page-header-title--with-catchcopy position-relative">
				<h1 class="iv2-page-header__main-title m-0">{{ items.title }}</h1>
				<p
					v-if="items.subtitle"
					class="iv2-page-header__sub-title m-0"
				>{{ items.subtitle }}</p>
			</div>
			<div class="iv2-page-header__text theme-f__page-header-text--with-catchcopy position-relative text-center">
				<div
					v-if="items.header_title && items.header_subtitle && items.header_summary_text"
					class="iv2-page-header__catchcopy row"
				>
					<div class="col-md-8 mx-auto">
						<p
							v-if="items.header_title"
							v-html="items.header_title"
							class="iv2-page-header__main-lead"
						></p>
					</div>
					<div class="col-md-8 mx-auto">
						<p
							v-if="items.header_subtitle"
							v-html="items.header_subtitle"
							class="iv2-page-header__sub-lead mb-0"
						></p>
						<p
							v-if="items.header_summary_text"
							v-html="items.header_summary_text"
							class="iv2-page-header__description mt-0"
						></p>
					</div>
				</div>
			</div>
		</div>
		<div
			v-else-if="items.header_type == 'add_bg'"
			class="iv2-page-header-type1 fit-img overflow-hidden pb-md-5"
		>
			<picture>
				<source
					type="image/webp"
					:srcset="items.header_image + publicLang.general.image_webp"
				>
				<img
					class="iv2-page-header__img"
					:src="items.header_image"
					:alt="items.title + publicLang.page.alt"
				>
			</picture>
			<div class="iv2-page-header__cover theme-f__page-header-bg--with-catchcopy position-absolute"></div>
			<div class="iv2-page-header__title position-relative mb-md-5">
				<h1 class="iv2-page-header__main-title theme-f__page-header-title--with-bg m-0">{{ items.title }}</h1>
				<p
					v-if="items.subtitle"
					class="iv2-page-header__sub-title theme-f__page-header-title--with-bg m-0"
				>{{ items.subtitle }}</p>
			</div>
		</div>
		<div
			v-else-if="items.header_type == 'add_cover'"
			class="iv2-page-header-type2 overflow-hidden"
		>
			<div class="iv2-page-header__title position-relative">
				<h1 class="iv2-page-header__main-title theme-f__page-header-title m-0" >{{ items.title }}</h1>
				<p
					v-if="items.subtitle"
					class="iv2-page-header__sub-title theme-f__page-header-sub-title m-0"
				>{{ items.subtitle }}</p>
			</div>
			<div class="iv2-page-header__img-band fit-img">
				<picture>
					<source
						type="image/webp"
						:srcset="items.header_image + publicLang.general.image_webp"
					>
					<img
						class="iv2-page-header__img"
						:src="items.header_image"
						:alt="items.title + publicLang.page.alt"
					>
				</picture>
				<div class="iv2-page-header__cover theme-f__page-header-bg--with-catchcopy position-absolute"></div>
			</div>
		</div>
		<div
			v-else-if="items.header_type == 'text_only'"
			class="iv2-page-header-type3 overflow-hidden"
		>
			<div class="iv2-page-header__title position-relative mb-5">
				<h1 class="iv2-page-header__main-title theme-f__page-header-title m-0">{{ items.title }}</h1>
				<p
					v-if="items.subtitle"
					class="iv2-page-header__sub-title theme-f__page-header-sub-title m-0"
				>{{ items.subtitle }}</p>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	props: {
		items: Object,
		publicLang: Object
	}
}
</script>

<style lang="scss">
.iv2-page-header {
	.iv2-page-header__title {
		.iv2-page-header__main-title {
			font-size: 1.5rem;
			font-weight: bold;
		}

		.iv2-page-header__sub-title {
			font-size: 1.2rem;
			font-weight: 600;
			line-height: 1;
		}
	}

	.iv2-page-header-type1,
	.iv2-page-header-type2,
	.iv2-page-header-type3 {
		padding-top: 80px;
	}

	.iv2-page-header-type1 {
		.iv2-page-header__img {
			width: 100%;
			height: 100%;
			z-index: 1;
		}

		.iv2-page-header__cover {
			width: 100%;
			height: 100%;
			z-index: 2;
			margin-top: -80px;
		}

		.iv2-page-header__title {
			text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
			z-index: 3;
		}

		.iv2-page-header__text {
			z-index: 3;
			text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);

			.iv2-page-header__main-lead {
				font-weight: bold;
			}

			.iv2-page-header__main-lead,
			.iv2-page-header__sub-lead,
			.iv2-page-header__description {
				line-height: 1.5;
			}
		}
	}

	.iv2-page-header-type2 {
		.iv2-page-header__img-band {
			overflow: hidden;

			.iv2-page-header__cover {
				width: 100%;
				height: 100%;
				z-index: 2;
			}
		}
	}
}

/* PC */
@media (min-width: 1200px) {
	.iv2-page-header {
		.iv2-page-header__title {
			padding: 50px 100px;
		}

		.iv2-page-header-type1 {
			.iv2-page-header__text {
				margin-bottom: 100px;
				.iv2-page-header__main-lead {
					font-size: 1.8rem;
				}
			}
		}

		.iv2-page-header-type2 {
			.iv2-page-header__img-band {
				height: 250px;
			}
		}
	}
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
	.iv2-page-header {
		.iv2-page-header__title {
			padding: 30px;
		}

		.iv2-page-header-type1 {
			.iv2-page-header__text {
				margin-bottom: 100px;
				.iv2-page-header__description {
					line-height: 2;
				}

				.iv2-page-header__main-lead {
					font-size: 2rem;
				}
			}
		}

		.iv2-page-header-type2 {
			.iv2-page-header__img-band {
				height: 150px;
			}
		}
	}
}

/* SP Only */
@media (max-width: 767px) {
	.iv2-page-header {
		.iv2-page-header__title {
			text-align: center;
			padding: 35px 20px;

			.iv2-page-header__main-title {
				font-size: 20px;
			}

			.iv2-page-header__sub-title {
				font-size: 14px;
			}
		}

		.iv2-page-header-type1,
		.iv2-page-header-type2,
		.iv2-page-header-type3 {
			padding-top: 60px;
		}

		.iv2-page-header-type1 {
			.iv2-page-header__cover {
				margin-top: -60px;
			}

			.iv2-page-header__text {
				.iv2-page-header__catchcopy {
					padding: 65px 20px 150px;

					.iv2-page-header__sub-lead,
					.iv2-page-header__description {
						font-size: 14px;
					}
				}
			}
		}

		.iv2-page-header-type2 {
			.iv2-page-header__img-band {
				height: 100px;
			}
		}
	}
}
</style>