<template>
	<section
		v-if="news.data.length > 0 && limit > 0 && indexOpenType == 1"
		:class="{ 'py-0': responsive === true, 'over-height': viewMore == 'rss' }"
		class="iv2-section-news theme__sub-bg"
	>
		<div class="container">
			<div class="row clearfix">
				<div class="column full text-center">
					<div class="spacer height-80"></div>
					<p class="size-14">{{ subtitle }}</p>
					<h3 class="iv2-top-news__title theme__heading-text">{{ title }}</h3>
					<div class="spacer height-40"></div>
				</div>
					<div
						v-for="item in limitCount"
						:key="item.id"
						class="iv2-top-news-item my-4 col-xl-6 col-12"
					>
						<router-link
							:to="{ name: 'newsDetail', params: { id: item.id }}"
							class="iv2-top-news-item__link"
						>
							<div class="iv2-top-news-item__block row">
								<div class="iv2-top-news-item__thumbnail fit-img col-md-3 col-12">
									<picture>
										<source
											type="image/webp"
											:srcset="item.thumbnail + publicLang.general.image_webp"
										>
										<img
											:src="item.thumbnail"
											class="iv2-top-news-item__img m-0"
											:alt="'「' + item.title + '」' + publicLang.news.alt"
										>
									</picture>
								</div>

								<div class="col-md-9 col-12 px-0 px-md-3">
									<h3 class="iv2-top-news-item__title m-md-0 mt-md-2">{{ item.title }}</h3>
									<p
										v-if="item.content"
										class="iv2-top-news-item__summary"
									>{{ item.content }}</p>
									<div class="d-flex text-left">
										<p
											v-if="item.opened_at"
											class="iv2-top-news-item__date mr-4"
										>{{ item.opened_at }}</p>
										<p class="iv2-top-news-item__label mr-4">{{ item.category_name }}</p>
										<p
											v-if="!item.days_excess" 
											class="iv2-top-news-item__new"
										>{{ themeLang.general.new_label }}</p>
									</div>
								</div>
							</div>
						</router-link>
					</div>
				<div class="column full">
					<div class="spacer height-40"></div>
					<div class="text-center">
						<span>
							<router-link
								to="/news"
								class="iv2-top-news-btn theme__main-btn is-btn is-btn-ghost2 size-16"
								>{{ publicLang.page.news_button }}</router-link
							>
							<!-- <a
								v-if="rss.is_linked_blog == 1"
								:href="rss.url"
								target="_blank"
								rel="noopener"
								class="iv2-top-news-btn theme__main-btn is-btn is-btn-ghost2 size-16"
							>
								<i class="fas fa-rss"></i>{{ publicLang.page.rss_button }}
							</a> -->
						</span>
					</div>
					<div class="spacer height-80"></div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	props: {
		items: Object,
		news: Object,
		blog: Object,
		language: String,
		publicLang: Object,
		themeLang: Object,
		showDisplay: String,
		responsive: Boolean
	},

	data() {
		return {
			title: '',
			subtitle: '',
			indexOpenType: '',
			limit: '',
			viewReturn: 'pattern',
			viewMore: '',
			height: '',
		};
	},

	computed: {
		limitCount() {
			return this.news.data.slice(0, this.limit);
		},
	},

	created() {
		this.limit = this.items.display_news_number;
		this.title = this.news.title;
		this.subtitle = this.news.subtitle;
		if (this.$route.query.mode == 'preview') {
			this.indexOpenType = 1;
		} else {
			this.indexOpenType = this.news.open_type;
		}
	},

	mounted() {
		if (this.responsive === false) {
			this.changeLayout();
		}
	},

	updated() {
		if (this.showDisplay == 'news' && this.responsive === false) {
			this.changeLayout();
		}
	},

	methods: {
		// 前後のセクションを定義
		changeLayout() {
			if (this.blog.is_linked_blog == 1 && this.blog.articles.length > 0) {
				this.viewMore = 'rss';
			}
			this.height = document.getElementsByClassName('iv2-section-news')[0].clientHeight
			this.$emit('view-more', this.viewMore, this.height);
			this.$emit('view-return', this.viewReturn);
		}
	}
}
</script>

<style lang="scss">
.iv2-section-news {
	padding-top: 80px;

	&.over-height {
		min-height: 100vh;
	}

	.iv2-top-news-title {
		font-size: 2.5rem;
	}

	.iv2-top-news-item {
		.iv2-top-news-item__link {
			display: block;
			text-decoration: none;

			&:hover .iv2-top-news-item__thumbnail {
				opacity: 0.7;
			}

			.iv2-top-news-item__block {
				height: 100%;
				justify-content: center;
			}

			.iv2-top-news-item__thumbnail {
				height: 123px;
				max-width: 123px;
				border: 1px solid #707070;
				-webkit-transition: 0.3s;
				transition: 0.3s;
			}

			.iv2-top-news-item__title {
				font-size: 1.2rem;
				font-weight: bold;
			}

			.iv2-top-news-item__summary {
				font-size: 1rem;
				margin: 0;
				line-height: 1.5;
				padding-top: 5px;
			}

			.iv2-top-news-item__date {
				line-height: 1;
				margin-right: 20px;
				font-size: 13px;
			}

			.iv2-top-news-item__new {
				color: #a83535;
			}

			.iv2-top-news-item__new,
			.iv2-top-news-item__label {
				line-height: 1;
				font-size: 13px;
				position: relative;

				&::before {
					content: '';
					height: 20px;
					width: 1px;
					background-color: #707070;
					position: absolute;
					top: 50%;
					left: -12px;
					transform: translateY(-50%);
				}
			}
		}
	}

	.iv2-top-news-btn {
		letter-spacing: 2px;
		max-width: 100%;
		width: 300px;
		padding-top: 15px;
		padding-bottom: 15px;
		margin: 10px;
	}

}

/* SP Only */
@media (max-width: 767px) {
	.iv2-section-news {
		padding-bottom: 100px;

		&.over-height {
			min-height: calc(100vh - 60px);
		}

		.iv2-top-news-item {
			.iv2-top-news-item__link {
				.iv2-top-news-item__thumbnail {
					height: 250px;
					max-width: unset;
				}
			}
		}

		.iv2-top-news-title {
			font-size: 2rem;
		}
	}
}
</style>