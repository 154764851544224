<template>
	<div class="iv2-drawer theme-f__drawer m-0">
		<div
			@click="touchHandler"
			class="iv2-drawer__btn d-inline-block"
		>
			<div
				:class="{ active: activeMenu }"
				class="iv2-drawer__btn-icon position-relative"
			>
				<span class="iv2-drawer__top-drawer theme-f__global-icon"></span>
				<span class="iv2-drawer__center-drawer theme-f__global-icon"></span>
				<span class="iv2-drawer__bottom-drawer theme-f__global-icon"></span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			activeMenu: false
		}
	},

	watch: {
		$route(to, from) {
			if (to.path !== from.path) {
				this.activeMenu = false;
			}
		},
	},

	methods: {
		touchHandler() {
			this.activeMenu = !this.activeMenu;

			if (this.activeMenu == true) {
				const el = document.body;
				el.classList.add('noscroll');
				const nav = document.getElementsByClassName('iv2-nav');
				nav[0].classList.add('active');
			} else {
				const el = document.body;
				el.classList.remove('noscroll');
				const nav = document.getElementsByClassName('iv2-nav');
				nav[0].classList.remove('active');
			}
		}
	}
}
</script>

<style lang="scss">
.iv2-drawer {
	height: 80px;
	position: fixed;
	top: 0;
	z-index: 111;

	.iv2-drawer__btn {
		.iv2-drawer__btn-icon {
			cursor: pointer;
			width: 25px;
			height: 22px;

			.iv2-drawer__top-drawer,
			.iv2-drawer__center-drawer,
			.iv2-drawer__bottom-drawer {
				display: block;
				position: absolute;
				width: 100%;
				height: 2px;
				margin: auto;
				right: 0;
				left: 0;
				transition: 0.3s;
			}

			.iv2-drawer__center-drawer {
				top: 0;
				bottom: 0;
				visibility: visible;
				opacity: 1;
			}

			&.active {
				.iv2-drawer__top-drawer {
					top: 0;
					bottom: 0;
					transform: rotate(-45deg);
				}

				.iv2-drawer__center-drawer {
					visibility: hidden;
					opacity: 0;
				}

				.iv2-drawer__bottom-drawer {
					top: 0;
					bottom: 0;
					transform: rotate(45deg);
				}
			}

			&:focus {
				outline: 0;
			}
		}
	}
}

@media (min-width: 1200px) {
	.iv2-drawer {
		padding: 29px 55px;
		left: 0;

		.iv2-drawer__btn {
			.iv2-drawer__btn-icon {
				.iv2-drawer__top-drawer {
					top: 1px;
				}

				.iv2-drawer__bottom-drawer {
					bottom: 1px;
				}
			}
		}
	}
}

@media (max-width: 1199px) {
	.iv2-drawer {
		right: 0;

		.iv2-drawer__btn {
			.iv2-drawer__btn-icon {
				.iv2-drawer__top-drawer {
					top: 1px;
				}

				.iv2-drawer__bottom-drawer {
					bottom: 1px;
				}
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 1199px) {
	.iv2-drawer {
		padding: 29px;
	}
}

@media (max-width: 767px) {
	.iv2-drawer {
		padding: 22px 16px;
		height: 60px;

		.iv2-drawer__btn {
			.iv2-drawer__btn-icon {
				width: 21px;
				height: 15px;

				.iv2-drawer__top-drawer {
					top: 0px;
				}

				.iv2-drawer__bottom-drawer {
					bottom: -1px;
				}
			}
		}
	}
}
</style>