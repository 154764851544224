<template>
	<div>
		<article class="iv2-article">
			<Error404
				v-if="openType === 0"
				:public-lang="publicLang"
			/>

			<div v-else-if="openType === 1">
				<BaseTitle
					:items="items"
					:public-lang="publicLang"
				/>
				<LayoutPattern
					:research="research"
					:language="language"
					:public-lang="publicLang"
				/>
			</div>
		</article>
		<div v-if="loading">
			<BaseLoading />
		</div>
	</div>
</template>

<script>
import BaseTitle from '@/components/items/BaseTitle.vue';
import LayoutPattern from './components/LayoutPattern.vue';
import BaseLoading from '@/components/items/BaseLoading.vue';
import Error404 from '@/components/systems/Page404.vue';
import Meta from '@/mixins/MetaSettable';

export default {
	components: {
		BaseTitle,
		LayoutPattern,
		BaseLoading,
		Error404
	},

	mixins: [Meta],

	props: {
		language: String,
		publicLang: Object
	},

	data() {
		return {
			research: [],
			items: {
				title: '',
				subtitle: '',
				header_type: 'text_only'
			},
			openType: '',
			loading: true
		}
	},

	mounted() {
		this.axios.get('/enquetes', {
			params: {
				hl: this.language,
			}
		}).then((response) => {
			this.research = response.data;
			this.items.title = response.data.title;
			this.items.subtitle = response.data.subtitle;
			if (this.$route.query.mode == 'preview') {
				this.openType = 1;
			} else {
				this.openType = response.data.open_type;
			}
		}).catch(() => {
			this.openType = 0;
		}).finally(() => {
			this.loading = false;
		});
	}
}
</script>

<style lang="scss"></style>