<template>
    <div>
        <h2 class="mb-1 iv-paragraph">回答締切</h2>
        <p class="mb-4 mt-0 iv-paragraph">{{ date }}</p>
    </div>
</template>

<script>
export default {
	props: {
		date: String
	}
}
</script>

<style lang="scss"></style>