import Vue from 'vue';
import axios from 'axios';
import VueAnalytics from 'vue-analytics';
import VueGtm from 'vue-gtm';

export default {
	data() {
		return {
			analytics: {},
			ogp: {},
			name: '',
			statusCode: '',
			global: [],
			extra: [],
			footer: [],
			count: {},
			organization: {},
			information: {},
			sns: {},
			rss: {},
			banner: {
				items: {},
				setting: {}
			},
			openType: '',
			translation: '',
			language: '',
			preview: false,
			themeLang: {
				general: {},
				color: {}
			},
			publicLang: {
				general: {},
				page: {},
				contact: {},
				news: {},
				download: {},
				coupon: {},
				research: {},
				system: {},
				language: {}
			},
		}
	},

	watch: {
		$route(to, from) {
			if (to.path !== from.path && this.analytics.access_type == 'ga' && this.open_type !== 0) {
				this.tracking();
			}
		}
	},

	mounted() {
		axios.get('/initials').then((response) => {
			/** ベースとなるlanguageの設定 */
			this.translation = response.data.response.translation;
			if (this.translation.is_use == 1) {
				var query = this.translation.active_language.filter(e => e.code == this.$route.query.hl);
				if (this.$route.query.hl && this.translation.is_use == 1 && query.length > 0) {
					this.language = this.$route.query.hl;
				} else if (this.$cookies.get('iv-language') && this.translation.is_use == 1) {
					this.language = this.$cookies.get('iv-language');
				} else {
					this.language = this.translation.default_lang;
				}
			} else {
				this.language = this.translation.default_lang;
			}
			this.statusCode = response.status;

			axios.get('/settings', {
				params: {
					hl: this.language,
				}
			}).then((response) => {
				this.analytics = response.data.response.analytics;
				this.ogp = response.data.response.sns;
				this.name = response.data.response.organization.name;
				if (this.$route.query.mode == 'preview') {
					this.openType = 1;
					this.preview = true;
				} else {
					this.openType = response.data.response.open_type;
					this.preview = false;
				}
				this.global = response.data.response.navigation.global;
				this.extra = response.data.response.navigation.extra;
				this.footer = response.data.response.navigation.footer;
				this.bottom = response.data.response.navigation.bottom;
				this.count = response.data.response.navigation.count;
				this.organization = response.data.response.organization;
				this.information = response.data.response.information;
				this.sns = response.data.response.sns;
				this.rss = response.data.response.blog;
				this.banner = response.data.response.banner;

				/** language用のcookie設定 */
				if (this.$route.query.hl && !this.$cookies.get('iv-language') && this.translation.is_use == 1) {
					var lang = this.$route.query.hl
					this.$cookies.set('iv-language', lang, '30min');
				}

				if (this.openType === 1) {

					/** レポート解析の設定 */
					if (this.analytics.is_use == 1) {
						if (this.analytics.access_type == 'ga') {
							// Google Analytics
							Vue.use(VueAnalytics, {
								id: this.analytics.tracking_id,
							});
							this.tracking();
						} else if (this.analytics.access_type == 'gtm') {
							// Google Tag Manager
							Vue.use(VueGtm, {
								id: this.analytics.container_id,
							});
						}
					}

					/** meta、OGPの設定 */
					document
						.querySelector('html')
						.setAttribute('lang', this.language);
					document
						.querySelector('link[rel="canonical"]')
						.setAttribute('href', location.href);
					document
						.querySelector('link[rel="icon"]')
						.setAttribute('href', this.information.favicon_image);
					document
						.querySelector('meta[property="og:url"]')
						.setAttribute('content', location.href);
					if (this.$route.path == '/') {
						document
							.querySelector('meta[property="og:type"]')
							.setAttribute('content', 'website');
					} else {
						document
							.querySelector('meta[property="og:type"]')
							.setAttribute('content', 'article');
					}
					document
						.querySelector('meta[property="og:site_name"]')
						.setAttribute('content', this.name);
					if (this.ogp.ogp_image) {
						document
							.querySelector('meta[property="og:image"]')
							.setAttribute('content', this.ogp.ogp_image);
					}
					if (this.ogp.twitter_id) {
						document
							.querySelector('meta[name="twitter:site"]')
							.setAttribute('content', '@' + this.ogp.twitter_id);
					}

					/** One Signalの設定 */
					if (response.data.response.onesignal.app_id) {
						var webpush = response.data.response.onesignal;
						var OneSignal = window.OneSignal || [];
						OneSignal.push(function () {
							OneSignal.init({
								appId: webpush.app_id,
								safari_web_id: webpush.safari_web_id
							});
						});
					}
				}

				// カラースキームの設定
				document.body.classList = 'theme-f__' + this.information.color_scheme;
			});

			/** ストレージから言語別の文字列を取得 */
			axios.get(process.env.VUE_APP_STORAGE_URL + 'common/lang/theme-f/' + this.language + '.json').then((response) => {
				this.themeLang.general = response.data.general;
				this.themeLang.color = response.data.color;
			});
			axios.get(process.env.VUE_APP_STORAGE_URL + 'common/lang/public/' + this.language + '.json').then((response) => {
				this.publicLang.general = response.data.general;
				this.publicLang.page = response.data.page;
				this.publicLang.contact = response.data.contact;
				this.publicLang.news = response.data.news;
				this.publicLang.download = response.data.download;
				this.publicLang.coupon = response.data.coupon;
				this.publicLang.research = response.data.research;
				this.publicLang.system = response.data.system;
				this.publicLang.language = response.data.language;
			});
		}).catch((error) => {
			axios.get(process.env.VUE_APP_STORAGE_URL + 'common/lang/public/ja.json').then((response) => {
				this.publicLang.general = response.data.general;
				this.publicLang.page = response.data.page;
				this.publicLang.contact = response.data.contact;
				this.publicLang.news = response.data.news;
				this.publicLang.download = response.data.download;
				this.publicLang.coupon = response.data.coupon;
				this.publicLang.research = response.data.research;
				this.publicLang.system = response.data.system;
				this.publicLang.language = response.data.language;
			});
			
			if (error.response === undefined) {
				this.statusCode = 503;
			} else {
				this.statusCode = error.response.status;
			}
		});
	},
	
	methods: {
		tracking(){
			this.$ga.page(this.$route.path);
		}
	}
}