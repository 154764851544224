<template>
	<header class="iv2-global theme-f__global-bg position-fixed">
		<div class="iv2-global__items position-relative">
			<h1 class="iv2-global__logo my-auto">
				<a
					v-if="!information.sp_logo_image_header == ''"
					:href="'/' + lang + query"
					class="iv2-global__link"
				>
					<picture class="d-none d-md-inline">
						<source
							type="image/webp"
							:srcset="information.logo_image_header + publicLang.general.image_webp"
						>
						<img
							:src="information.logo_image_header"
							class="iv2-global__logo-img theme-f__global-logo"
							:alt="organization.name"
						>
					</picture>
					<picture class="d-md-none">
						<source
							type="image/webp"
							:srcset="information.sp_logo_image_header + publicLang.general.image_webp"
						>
						<img
							:src="information.sp_logo_image_header"
							class="iv2-global__logo-img theme-f__global-logo"
							:alt="organization.name"
						>
					</picture>
				</a>
				<a
					v-else
					:href="'/' + lang + query"
					class="iv2-global__link"
				>
					<picture>
						<source
							type="image/webp"
							:srcset="information.logo_image_header + publicLang.general.image_webp"
						>
						<img
							:src="information.logo_image_header"
							class="iv2-global__logo-img theme-f__global-logo"
							:alt="organization.name"
						>
					</picture>
				</a>
			</h1>
		</div>

		<nav class="iv2-nav theme-f__nav-bg">
			<div class="iv2-nav__block">
				<div class="iv2-nav__list">
					<div
						v-for="nav in global"
						:key="nav.id"
						:class="{active: activeParent == nav.id}"
						class="iv2-nav__item theme-f__nav-item"
					>
						<router-link
							v-if="nav.link_type == 'internal' && !nav.children.length == true"
							:to="nav.link_href + lang + query"
							:target="nav.target_type"
							@click.native="closeMenu"
							class="iv2-nav__link theme-f__nav-link"
						>{{ nav.item_name }}</router-link>
						<a
							v-else-if="nav.link_type == 'external' && !nav.children.length == true"
							:href="nav.link_href"
							:target="nav.target_type"
							@click="closeMenu"
							rel="noopener"
							class="iv2-nav__link theme-f__nav-link"
						>{{ nav.item_name }}</a>
						<router-link
							v-if="nav.link_type == 'internal' && !nav.children.length == false"
							:to="nav.link_href + lang + query"
							:target="nav.target_type"
							@click.native="closeMenu"
							class="iv2-nav__link theme-f__nav-link"
						>{{ nav.item_name }}</router-link>
						<a
							v-else-if="nav.link_type == 'external' && !nav.children.length == false"
							:href="nav.link_href"
							:target="nav.target_type"
							@click="closeMenu"
							rel="noopener"
							class="iv2-nav__link theme-f__nav-link"
						>{{ nav.item_name }}</a>
						<a
							v-if="!nav.children.length == false"
							@click="activeList(nav.id)"
							class="iv2-nav__acordion theme-f__nav-acordion d-inline-block d-xl-none"
							href="javascript:void(0)"
						></a>
						<div
							v-if="!nav.children.length == false"
							class="iv2-nav__list"
						>
							<div
								v-for="cNav in nav.children"
								:key="cNav.id"
								class="iv2-nav__item theme-f__nav-item--inner"
							>
								<router-link
									v-if="cNav.link_type == 'internal'"
									:to="cNav.link_href + lang + query"
									:target="cNav.target_type"
									@click.native="closeMenu"
									class="iv2-nav__link theme-f__nav-link--inner"
								>{{ cNav.item_name }}</router-link>
								<a
									v-else-if="cNav.link_type == 'external'"
									:href="cNav.link_href"
									:target="cNav.target_type"
									@click="closeMenu"
									rel="noopener"
									class="iv2-nav__link theme-f__nav-link--inner"
								>{{ cNav.item_name }}</a>
							</div>
						</div>
					</div>
				</div>
				<div class="iv2-nav__extra">
					<div class="iv2-nav__extra__block theme-f__nav-border d-flex">
						<div
							v-for="subnav in extra"
							:key="subnav.id"
							class="iv2-nav__extra-item d-inline-block text-center"
						>
							<div v-if="subnav.link_type == 'internal'">
								<router-link
									:to="subnav.link_href + lang + query"
									class="iv2-nav__extra-link theme-f__nav-link"
								>
									<i
										class="iv2-nav__extra-item-icon d-block"
										:class="subnav.item_icon"
									></i>
									<span class="iv2-nav__extra-item-text d-block">{{ subnav.item_name }}</span>
								</router-link>
							</div>
							<div v-else-if="subnav.link_type == 'external'">
								<a
									:href="subnav.link_href"
									:target="subnav.target_type"
									rel="noopener"
									class="iv2-nav__extra-link theme-f__nav-link"
								>
									<i
										class="iv2-nav__extra-item-icon d-block"
										:class="subnav.item_icon"
									></i>
									<span class="iv2-nav__extra-item-text d-block">{{ subnav.item_name }}</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="iv2-nav__footer">
				<div
					v-if="!(sns.is_linked_facebook == 0 && sns.is_linked_instagram == 0 && sns.is_linked_twitter == 0 && sns.is_linked_line == 0)"
					class="iv2-nav__mobile-sns theme-f__nav-sns-border m-auto"
				>
					<ul class="iv2-nav__mobile-sns-list list-unstyled d-flex">
						<li
							v-if="sns.is_linked_facebook == 1"
							class="iv2-nav__mobile-sns-item"
						>
							<a
								:href="sns.facebook_url"
								class="iv2-nav__mobile-sns-link theme-f__nav-sns-link"
								target="_blank"
								rel="noopener"
							>
								<i class="fab fa-facebook-f"></i>
							</a>
						</li>
						<li
							v-if="sns.is_linked_instagram == 1"
							class="iv2-nav__mobile-sns-item"
						>
							<a
								:href="sns.instagram_url"
								class="iv2-nav__mobile-sns-link theme-f__nav-sns-link"
								target="_blank"
								rel="noopener"
							>
								<i class="fab fa-instagram"></i>
							</a>
						</li>
						<li
							v-if="sns.is_linked_twitter == 1"
							class="iv2-nav__mobile-sns-item"
						>
							<a
								:href="sns.twitter_url"
								class="iv2-nav__mobile-sns-link theme-f__nav-sns-link"
								target="_blank"
								rel="noopener"
							>
								<i class="fab fa-twitter"></i>
							</a>
						</li>
						<li
							v-if="sns.is_linked_line == 1"
							class="iv2-nav__mobile-sns-item"
						>
							<a
								:href="sns.line_url"
								class="iv2-nav__mobile-sns-link theme-f__nav-sns-link"
								target="_blank"
								rel="noopener"
							>
								<i class="fab fa-line"></i>
							</a>
						</li>
						<!-- <li
							v-if="rss.is_linked_blog == 1"
							class="iv2-nav__mobile-sns-item"
						>
							<a
								:href="rss.url"
								class="iv2-nav__mobile-sns-link theme-f__nav-sns-link"
								target="_blank"
								rel="noopener"
							>
								<i class="fas fa-rss"></i>
							</a>
						</li> -->
					</ul>
				</div>
				<div
					v-if="organization.copy_right"
					class="iv2-nav__copyright theme-f__nav-text text-center"
				>
					<p class="iv2-nav__copyright--text">{{ organization.copy_right }}</p>
				</div>
			</div>
		</nav>

		<!-- フリーページ項目に対応した動的height値指定 -->
		<div is="style">
			@media (min-width: 1200px) {
				{{ setHeight() }}
			}
		</div>
		<!-- フリーページ項目に対応した動的height値指定 -->
	</header>
</template>

<script>
export default {
	props: {
		global: Array,
		extra: Array,
		sns: Object,
		organization: Object,
		information: Object,
		rss: Object,
		publicLang: Object,
		count: Number
	},

	data() {
		return {
			query: '',
			lang: '',
			activeParent: false,
			activeNav: '',
			navColorClass: '',
			environment: ''
		}
	},

	watch: {
		$route(to, from) {
			if (to.path !== from.path) {
				const el = document.body;
				el.classList.remove('noscroll');
				const nav = document.getElementsByClassName('iv2-nav');
				nav[0].classList.remove('active');
				this.activeParent = null;
			}
		}
	},

	mounted() {
		if (this.$route.query.mode == 'preview') {
			this.query = '?mode=preview';
		}
		if (this.$route.query.hl) {
			this.lang = '?hl=' + this.$route.query.hl;
		}
	},

	methods: {
		activeList(key) {
			if (this.activeParent === key){
				this.activeParent = null;
			} else {
				this.activeParent = key;
			}
		},

		closeMenu() {
			this.activeParent = false;
			const el = document.body;
			el.classList.remove('noscroll');
			const nav = document.getElementsByClassName('iv2-nav');
			nav[0].classList.remove('active');
			const drawer = document.getElementsByClassName('iv2-drawer__btn-icon');
			drawer[0].classList.remove('active');
		},

		/** フリーページにより大きく可変するナビゲーションに対応した高さを指定 */
		setHeight() {
			let navHeight = this.count * 30;
			if (navHeight > 500) {
				return '.iv2-global .iv2-nav .iv2-nav__block>.iv2-nav__list {max-height: ' + navHeight + 'px;}';
			}
		}
	}
}
</script>

<style lang="scss">
@keyframes tooltipShow {
	from {
		opacity: 0;
		transform: translateY(-20px);
	}

	to {
		opacity: 1;
	}
}

.iv2-global {
	transition: .5s;
	
	.iv2-global__items {
		height: 100%;
		display: flex;

		.iv2-global__logo {
			position: relative;

			.iv2-global__link {
				cursor: pointer;
			}

			.iv2-global__logo-img {
				max-width: 100%;
				max-height: 100%;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				-webkit-transform: translateY(-50%);
			}
		}
	}

	.iv2-nav {
		height: calc(100vh - 80px);
		width: 100%;
		visibility: hidden;
		display: none;
		position: absolute;
		left: 0;
		overflow-y: auto;
		overflow-x: hidden;

		&.active {
			visibility: visible;
			animation: tooltipShow 0.5s linear 0s;
		}

		.iv2-nav__block {
			.iv2-nav__extra {
				.iv2-nav__extra__block {
					.iv2-nav__extra-item {
						margin: 0 15px;

						.iv2-nav__extra-link {
							&:hover {
								text-decoration: none;
							}

							.iv2-nav__extra-item-icon {
								margin-bottom: 8px;
							}

							.iv2-nav__extra-item-text {
								line-height: 1.2;
							}
						}
					}
				}
			}

			>.iv2-nav__list {
				.iv2-nav__item {
					font-size: 13px;
					letter-spacing: 1.2px;
					white-space: pre-wrap;

					.iv2-nav__link {
						line-height: 1.3;

						&:hover {
							text-decoration: none;
						}
					}

					> .iv2-nav__list {
						padding-left: 20px;
						padding-left: 0;
					}
				}
			}
		}
	}
}

/* PC */
@media (min-width: 1200px) {
	.iv2-global {
		.iv2-global__items {
			flex-direction: row-reverse;
			justify-content: space-between;

			.iv2-global__logo {
				width: 240px;
				height: 60px;
				margin-right: 25px;

				.iv2-global__logo-img {
					margin-left: auto;
					right: 0;
				}
			}
		}

		.iv2-nav {
			&.active {
				display: block;
			}

			.iv2-nav__block {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 100%;
				overflow-y: auto;
				overflow-x: hidden;
				top: 0px;

				.iv2-nav__extra {
					.iv2-nav__extra__block {
						justify-content: center;
						padding-top: 2rem;
						margin: 0 5rem;
						height: 120px;

						.iv2-nav__extra-item {
							width: 110px;

							.iv2-nav__extra-link {
								.iv2-nav__extra-item-icon,
								.iv2-nav__extra-item-text {
									font-size: 16px;
								}
							}
						}
					}
				}

				> .iv2-nav__list {
					display: flex;
					flex-wrap: wrap;
					flex-direction: column;
					align-content: flex-start;
					padding: 4rem 5rem 2rem;
					max-height: calc(100vh - 210px);

					.iv2-nav__item {
						width: 33.3%;
						font-size: 16px;

						.iv2-nav__link {
							display: inline-block;
							padding: 12px 20px;
						}

						> .iv2-nav__list {
							.iv2-nav__item {
								width: 100%;
								margin-left: 40px;
								position: relative;
							}
						}
					}
				}
			}

			.iv2-nav__footer {
				display: none;
			}
		}
	}
}

/* TABLET or SP */
@media (max-width: 1199px) {
	.iv2-global {
		.iv2-nav {
			width: 100%;

			&.active {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}

			.iv2-nav__block {
				display: flex;
				flex-direction: column-reverse;
				.iv2-nav__list {
					.iv2-nav__item {
						position: relative;
						display: block;
						font-size: 1rem;
						margin: 0;

						.iv2-nav__link {
							display: block;
							padding: 10px 0;
						}

						> .iv2-nav__list {
							padding: 0;
							visibility: hidden;
							opacity: 0;
							height: 0;

							&.active {
								visibility: visible;
								opacity: 1;
								height: auto;
								transition: 0.5s;
							}
						}

						.iv2-nav__acordion {
							width: 55px;
							height: 42px;
							position: absolute;
							right: 0;
							top: 0;

							&::before {
								content: "";
								width: 15px;
								height: 15px;
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%)rotate(-45deg);
								transition: 0.2s;
							}
						}

						&.active {
							> .iv2-nav__list {
								visibility: visible;
								opacity: 1;
								height: auto;
								> .iv2-nav__item {
									font-size: 1.1rem;
								}
							}

							.iv2-nav__acordion {
								&::before {
									top: 40%;
									transform: translate(-50%, -50%)rotate(-135deg);
								}
							}
						}
					}
				}
			}

			.iv2-nav__footer {
				padding: 0 20px;

				.iv2-nav__mobile-sns {
					.iv2-nav__mobile-sns-list {
						width: 100%;
						justify-content: center;

						.iv2-nav__mobile-sns-item {
							font-size: 20px;
							margin-right: 20px;

							&:last-child {
								margin-right: 0;
							}
						}
					}
				}

				.iv2-nav__copyright {
					.iv2-nav__copyright--text {
						font-size: 10px;
						letter-spacing: 4px;
					}
				}
			}

			
		}
	}
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
	.iv2-global {
		.iv2-global__items {
			justify-content: space-between;

			.iv2-global__logo {
				width: 200px;
				height: 50px;
				margin-left: 25px;

				.iv2-global__logo-img {
					left: 0;
				}
			}
		}

		.iv2-nav {
			.iv2-nav__block {
				padding: 50px 75px 0;

				.iv2-nav__extra {
					padding-bottom: 50px;

					.iv2-nav__extra__block {
						.iv2-nav__extra-item {
							width: 100px;
							margin: 0;
							margin-right: 15px;
						}
					}
				}

				.iv2-nav__list {
					position: relative;

					.iv2-nav__item {
						> .iv2-nav__list {
							&::before {
								content: none;
							}

							.iv2-nav__item {
								margin-left: 20px;
							}
						}
					}
				}
			}
		}
	}
}

/* SP Only */
@media (max-width: 767px) {
	.iv2-global {
		.iv2-global__items {
			.iv2-global__logo {
				width: 160px;
				height: 32px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

				.iv2-global__link {
					position: relative;
					display: block;
					height: 100%;
				}

				.iv2-global__logo-img {
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}

		.iv2-nav {
			height: calc(100vh - 120px);
			padding-bottom: 10rem;
			padding-bottom: calc(10rem + env(safe-area-inset-bottom));
			top: 60px;

			.iv2-nav__block {
				padding: 30px 20px 0;

				.iv2-nav__extra {
					display: none;
				}
				
				.iv2-nav__list {
					text-align: center;
					
					.iv2-nav__item {
						.iv2-nav__link {
							padding: 10px 40px;
							line-height: 1.5;
						}
					}
				}
			}

			.iv2-nav__footer {
				.iv2-nav__mobile-sns {
					.iv2-nav__mobile-sns-list {
						margin: 0;
					}
				}

				.iv2-nav__copyright {
					.iv2-nav__copyright--text {
						margin: 10px 0;
					}
				}
			}
		}
	}
}
</style>