<template>
    <div>
        <article class="iv2-article">
            <transition
                mode="out-in"
                :name="animation"
                appear
            >
                <div v-if="pageActive">
                    <section class="container-fluid">
                        <div class="row justify-content-center">
                            <div class="col-lg-8 pb-5 px-0">
                                <CustomizeLayoutPattern
                                    v-if="!expired.before && !expired.after"
                                    :forms="forms"
                                    :start="openStartDate"
                                    :end="openEndDate"
                                    :num="itemNum"
                                    :time="answersTime"
                                    :moduleSettings="moduleSettings"
                                    />
                                <CustomizeExpiredBefore
                                    v-else-if="expired.before"
                                    :forms="forms"
                                    :start="openStartDate"
                                    :end="openEndDate"
                                    :num="itemNum"
                                    :time="answersTime"
                                    :moduleSettings="moduleSettings"
                                    />
                                <CustomizeExpiredAfter
                                    v-else-if="expired.after"
                                    :forms="forms"
                                    :start="openStartDate"
                                    :end="openEndDate"
                                    :num="itemNum"
                                    :time="answersTime"
                                    :moduleSettings="moduleSettings"
                                    />
                            </div>
                        </div>
                    </section>
                </div>
            </transition>

            <div v-if="loading">
                <BaseLoading />
            </div>
        </article>
    </div>
</template>

<script>
import CustomizeLayoutPattern from './components/CustomizeLayoutPattern.vue';
import CustomizeExpiredBefore from './components/CustomizeExpiredBefore.vue';
import CustomizeExpiredAfter from './components/CustomizeExpiredAfter.vue';
import BaseLoading from '@/components/items/BaseLoading.vue';
import Meta from '@/mixins/MetaSettable';
import Hash from '@/mixins/AnchorLink';
import Preview from '@/mixins/Preview';

export default {
    components: {
        CustomizeLayoutPattern,
        CustomizeExpiredBefore,
        CustomizeExpiredAfter,
        BaseLoading
    },

    mixins: [Meta, Hash, Preview],

    props: {
        animation: String,
        language: String,
        publicLang: Object
    },

    data() {
        return {
            items: [],
            openType: '',
            indexType: '',
            loading: true,
            pageActive: false,
            forms: {},
            expired: {
                before: false,
                after: false
            },
            openStartDate: '',
            openEndDate: '',
            itemNum: 24,
            answersTime: 10,
            headline: '',
            moduleSettings: {}
        }
    },

    mounted() {
        this.load();
    },

    methods: {
        load() {
            this.moduleSettings.headline = 'スタッフ意識調査';
            this.moduleSettings.siteId = document.head.querySelector("[name=sid][content]").content;

            this.moduleSettings.baseCoreUrl = process.env.VUE_APP_CORE_URL;
            this.moduleSettings.baseFormUrl = process.env.VUE_APP_FORM_MODULE_URL;
            this.moduleSettings.codePath = this.moduleSettings.siteId + process.env.VUE_APP_CODE_FILE;
            this.moduleSettings.formId = process.env.VUE_APP_FORM_MODULE_ID;
            this.moduleSettings.codeId = process.env.VUE_APP_FORM_MODULE_ITEM_CODE_ID;
            this.moduleSettings.nameId = process.env.VUE_APP_FORM_MODULE_ITEM_NAME_ID;

            switch (this.$route.path) {
                case '/starbuckses':
                    this.itemNum = 22;
                    this.moduleSettings.headline = 'スターバックス　スタッフ意識調査';
                    this.moduleSettings.baseCoreUrl = process.env.VUE_APP_SB_CORE_URL;
                    this.moduleSettings.baseFormUrl = process.env.VUE_APP_SB_FORM_MODULE_URL;
                    this.moduleSettings.codePath = this.moduleSettings.siteId + process.env.VUE_APP_SB_CODE_FILE;
                    this.moduleSettings.formId = process.env.VUE_APP_SB_FORM_MODULE_ID;
                    this.moduleSettings.codeId = process.env.VUE_APP_SB_FORM_MODULE_ITEM_CODE_ID;
                    this.moduleSettings.nameId = process.env.VUE_APP_SB_FORM_MODULE_ITEM_NAME_ID;
                    break;
            }

            const self = this;

            this.axios({
                url: process.env.VUE_APP_CORE_MODULE_PATH
                    + process.env.VUE_APP_CORE_VERSION_PAHT
                    + process.env.VUE_APP_ID + '/'
                    + self.moduleSettings.formId + '/'
                    + 'show',
                baseURL: this.moduleSettings.baseCoreUrl
            }).then((response) => {
                let currentDate = new Date;
                if (response != undefined
                    && response.status != undefined
                    && response.status === 200
                    && response.data.success != undefined
                    && response.data.success === true
                ) {
                    this.forms = response.data.response.data;
                    this.openType = 1;

                    /* 回答期限切れかどうかの判定 */
                    this.forms.open_start_date_instance = new Date(this.setFormatDate(this.forms.open_start_date));
                    this.forms.open_end_date_instance = new Date(this.setFormatDate(this.forms.open_end_date));

                    this.openStartDate = this.getDate(this.forms.open_start_date_instance);
                    this.openEndDate = this.getDate(this.forms.open_end_date_instance);

                    // 開始日時が null ではない
                    // 現在日時が、開始日時より前の日時である
                    // --> 「期限前フラグ」を立てる
                    this.expired.before = (this.forms.open_start_date != null && currentDate < this.forms.open_start_date_instance);

                    // 終了日時が null ではない
                    // 現在日時が、終了日時より後の日時である
                    // --> 「期限後フラグ」を立てる
                    this.expired.after = (this.forms.open_end_date != null && currentDate > this.forms.open_end_date_instance);
                }
            }).catch(() => {
                this.openType = 0;
            }).finally(() => {
                this.loading = false;
                this.pageActive = true;
            });
        },
        getDate(instance) {
            const weeks = ['日', '月', '火', '水', '木', '金', '土'];
            return instance.getFullYear() + '年'
                + (instance.getMonth() + 1) + '月'
                + instance.getDate() + '日'
                + ' (' + weeks[instance.getDay()] + ')'
        },
        setFormatDate(dt) {
            return dt.replace(/-/g,"/");
        }
    }
};
</script>

<style lang="scss">
    .iv2-global, .iv2-footer, .iv2-drawer, .iv2-sidebar, .iv2-bottom-nav {
        display: none;
    }
    .iv2-article {
        * {
            color: #000;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
            font-weight: 400;
            -webkit-font-smoothing: auto;
        }
        .banners {
            display: none;
        }
    }
    .iv-paragraph {
        font-size: 1.2rem;
        line-height: 1.75;
    }
</style>
