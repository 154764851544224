<template>
	<transition>
		<div
			class="iv2__view-return-btn size-14 position-fixed"
			@click="slideLayout"
		>
			<div class="iv2__view-return-arrow">
				<span class="iv2__first-arrow"></span>
				<span class="iv2__second-arrow"></span>
			</div>
		</div>
	</transition>
</template>

<script>
export default {

	props: {
		viewReturn: String
	},

	data() {
		return {
			changeLayout: '',
		}
	},

	methods: {
		slideLayout() {
			this.changeLayout = this.viewReturn;
			this.$emit('change-layout', this.changeLayout);
		}
	},
}
</script>

<style lang="scss">
.iv2__view-return-btn {
	cursor: pointer;
	display: block;
	top: 80px;
	left: 50%;
	transform: translateX(-50%);
	color: #fff !important;
	z-index: 100;
	visibility: hidden;
	opacity: 0;
	transition: .3s;

	.iv2__view-return-arrow {
		content: "";
		height: 35px;
		width: 35px;
		background-color: #fff;
		border-radius: 50px;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 25px;
		opacity: 0.7;
		filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));

		.iv2__first-arrow {
			&::before {
				content: "";
				border-left: 1px solid #3C3C3C;
				width: 1px;
				height: 11px;
				position: absolute;
				top: 25%;
				left: 12.5px;
				transform: rotate(-120deg);
			}

			&::after {
				content: "";
				border-left: 1px solid #3C3C3C;
				width: 1px;
				height: 11px;
				position: absolute;
				top: 25%;
				right: 12.5px;
				transform: rotate(120deg);
			}
		}

		.iv2__second-arrow {
			&::before {
				content: "";
				border-left: 1px solid #3C3C3C;
				width: 1px;
				height: 11px;
				position: absolute;
				top: 37%;
				left: 12.5px;
				transform: rotate(-120deg);
			}

			&::after {
				content: "";
				border-left: 1px solid #3C3C3C;
				width: 1px;
				height: 11px;
				position: absolute;
				top: 37%;
				right: 12.5px;
				transform: rotate(120deg);
			}
		}
	}

	&.scroll {
		visibility: visible;
		opacity: 1;
	}
}

/* SP Only */
@media (max-width: 767px) {
	.iv2__view-return-btn {
		top: 50px;
	}
}
</style>