<template>
	<div
		class="container-fluid"
		v-html="coupon.content"
	></div>
</template>

<script>
export default {
	props: {
		coupon: Object
	}
}
</script>

<style lang="scss"></style>