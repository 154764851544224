import route from "@/router";

// 固定ページ
import Fixed from "@/components/pages/fixed_page/App.vue";
// coupon
import CouponList from "@/components/pages/coupon_list/App.vue";
import CouponDetail from "@/components/pages/coupon_detail/App.vue";
// news
import NewsList from "@/components/pages/news_list/App.vue";
import NewsDetail from "@/components/pages/news_detail/App.vue";
// download
import Download from "@/components/pages/download/App.vue";
// research
import Research from "@/components/pages/research/App.vue";
// contact
import Contact from "@/components/pages/contact/App.vue";
// freepage
import Freepage from "@/components/pages/free_page/App.vue";
// 404エラー
import NotFound from "@/components/systems/Page404.vue";

export default {
	created() {
		// ルーティングの追加
		const routes = [];
		this.axios.get(process.env.VUE_APP_STORAGE_URL + 'common/data/route.json').then(function (res) {
			res.data.forEach(function (v) {
				if (v.name == 'coupon') {
					routes.push({
						path: v.path,
						name: v.name,
						component: CouponList
					});
				} else if (v.name == 'couponDetail') {
					routes.push({
						path: v.path,
						name: v.name,
						component: CouponDetail
					});
				} else if (v.name == 'news') {
					routes.push({
						path: v.path,
						name: v.name,
						component: NewsList
					});
				} else if (v.name == 'newsDetail') {
					routes.push({
						path: v.path,
						name: v.name,
						component: NewsDetail
					});
				} else if (v.name == 'download') {
					routes.push({
						path: v.path,
						name: v.name,
						component: Download
					});
				} else if (v.name == 'research') {
					routes.push({
						path: v.path,
						name: v.name,
						component: Research
					});
				} else if (v.name == 'contact') {
					routes.push({
						path: v.path,
						name: v.name,
						component: Contact
					});
				} else if (v.name == 'NotFound') {
					routes.push({
						path: v.path,
						name: v.name,
						component: NotFound
					});
				} else if (v.segment == 'free_page') {
					routes.push({
						path: v.path,
						name: v.name,
						component: Freepage
					});
				} else {
					routes.push({
						path: v.path,
						name: v.name,
						component: Fixed
					});
				}
			});
			route.addRoutes(routes);
		})
	}
}