<template>
	<div class="container">
		<div class="row clearfix">
			<div
				v-if="contact.form_name"
				class="column full"
			>
				<h3 class="iv2-contact-form__title">{{ contact.form_name }}</h3>
			</div>
			<div
				v-if="contact.form_description"
				class="column full"
			>
				<p class="iv2-contact-form__sumally text-pre">{{ contact.form_description }}</p>
			</div>
			<div class="column full">
				<div class="spacer height-20"></div>
			</div>
			<form @submit.prevent="submit">
				<loading
					:active.sync="isLoading"
					:is-full-page="fullPage"
					:background-color="bgColor"
				></loading>
				<div
					v-if="success"
					class="iv2-contact-form__response-text text-pre"
				>{{ publicLang.contact.send_text }}</div>
				<div
					v-else-if="timeout"
					class="iv2-contact-form__response-text text-pre"
				>{{ publicLang.contact.send_error }}</div>
				<div
					v-else-if="invalid"
					class="m-4 text-danger text-pre"
				>{{ publicLang.contact.send_invalid }}</div>
				<div class="column full">
					<label class="d-block mt-3">
						<span class="badge badge-danger mr-2">{{ publicLang.general.required }}</span>
						<span>{{ publicLang.contact.basic_name }}</span>
					</label>
					<input
						type="text"
						class="iv2-contact-form__item w-75 p-2"
						name="name"
						id="name"
						v-model="fields.name"
						:placeholder="publicLang.contact.basic_name"
					>
					<div
						v-if="errors && errors.name"
						class="text-danger"
					>{{ errors.name[0] }}</div>
				</div>
				<div class="column full">
					<label class="d-block mt-3">
						<span class="badge badge-danger mr-2">{{ publicLang.general.required }}</span>
						<span>{{ publicLang.contact.tel }}</span>
					</label>
					<input
						type="tel"
						class="iv2-contact-form__item w-75 p-2"
						name="tel"
						id="tel"
						v-model="fields.tel"
						:placeholder="publicLang.contact.tel"
					>
					<div
						v-if="errors && errors.tel"
						class="text-danger"
					>{{ errors.tel[0] }}</div>
				</div>
				<div class="column full">
					<label class="d-block mt-3">
						<span class="badge badge-danger mr-2">{{ publicLang.general.required }}</span>
						<span>{{ publicLang.contact.email }}</span>
					</label>
					<input
						type="email"
						class="iv2-contact-form__item w-75 p-2"
						name="email"
						id="email"
						v-model="fields.email"
						:placeholder="publicLang.contact.email"
					>
					<div
						v-if="errors && errors.email"
						class="text-danger"
					>{{ errors.email[0] }}</div>
				</div>
				<div class="column full">
					<label class="d-block mt-3">
						<span class="badge badge-danger mr-2">{{ publicLang.general.required }}</span>
						<span>{{ publicLang.contact.message }}</span>
					</label>
					<textarea
						class="iv2-contact-form__item w-100 p-2"
						id="message"
						name="message"
						rows="5"
						v-model="fields.message"
					></textarea>
					<div
						v-if="errors && errors.message"
						class="text-danger"
					>{{ errors.message[0] }}</div>
				</div>
				<div class="column full">
					<p class="text-center text-pre mb-4">{{ publicLang.contact.warning }}</p>
					<div v-if="policy === 1">
						<p class="text-center mb-0">
							<router-link
								to="/privacypolicy"
								target="_blank"
								class="iv2-contact__privacy-link"
							>{{ publicLang.contact.privacy_link }}</router-link>{{ publicLang.contact.privacy_text }}
						</p>
						<div class="text-center">
							<label style="cursor: pointer;">
								<input
									type="checkbox"
									name="agreement"
									id="agreement"
									v-model="fields.agreement"
								>{{ publicLang.contact.agreement }}
								<div
									v-if="errors && errors.agreement"
									class="text-danger"
								>{{ errors.agreement[0] }}</div>
							</label>
						</div>
					</div>
					<p class="text-center mt-5">
						<input
							class="iv2-contact-form__btn theme__main-btn"
							type="submit"
							:value="publicLang.contact.button"
							:disabled="!fields.agreement"
						>
					</p>
					<div class="spacer height-100"></div>
				</div>
				<div v-if="policy === 0">
					<input
						type="hidden"
						v-model="fields.agreement"
					>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	components: {
		Loading
	},

	props: {
		contact: Object,
		language: String,
		publicLang: Object
	},

	data() {
		return {
			fields: {
				name: '',
				tel: '',
				email: '',
				message: '',
				agreement: ''
			},
			errors: {},
			policy: '',
			success: false,
			timeout: false,
			invalid: false,
			fullPage: true,
			isLoading: false,
			bgColor: '#525252'
		}
	},

	mounted() {
		this.axios.get(process.env.VUE_APP_CONTACT_POLICY, {
			params: {
				hl: this.language,
			}
		}).then((response) => {
			this.policy = response.data.response.open_type;
			if (this.policy == 0) {
				this.fields.agreement = true;
			} else {
				this.fields.agreement = '';
			}
		})
	},

	methods: {
		submit() {
			this.isLoading = true;
			this.success = false;
			this.timeout = false;
			this.invalid = false;
			this.errors = {};

			this.axios
				.post('/contacts/' + process.env.VUE_APP_CONTACT_GEN,
					this.fields,
					{
						withCredentials: true,
						timeout : 30000
					}
				).then(() => {
					this.fields = {
						name: '',
						tel: '',
						email: '',
						message: ''
					}; //Clear input fields.
					if (this.policy == 0) {
						this.fields.agreement = true;
					} else {
						this.fields.agreement = '';
					}
					this.success = true;
					// console.log(response);
				}).catch((error) => {
					const isTimeout = error.code === 'ECONNABORTED';
					if (isTimeout || !error.response) {
						this.timeout = true;
					}
					if (error.response.status === 422) {
						this.errors = error.response.data.errors || {};
						this.invalid = true;
					} else {
						this.timeout = true;
					}
				}).finally(() => {
					this.isLoading = false;
					window.scrollTo({
						top: 0,
						behavior: 'smooth'
					});
				});
		}
	}
}
</script>

<style lang="scss">
.iv2-contact-form__sumally {
	letter-spacing: 3px;
}

.iv2-contact-form__response-text {
	background-color: #ddd;
	color: #000;
	margin: 1rem 0;
	padding: 0.75rem 1.25rem;
	border-radius: 5px;
}

.iv2-contact-form__item {
	border: 1px solid #8c8c8c;
}

.iv2-contact-form__btn {
	text-align: center;
	display: inline-block;
	border: 0;
	font-size: 18px;
	letter-spacing: 2px;
	padding-top: 15px;
	padding-bottom: 15px;
	border-radius: 0;
	width: 300px;
	max-width: 100%;
}

input[type="submit"][disabled] {
  opacity: .2;

	&:hover {
		transform: scale(1);
		opacity: .2;
	}
}

@media (max-width: 1199px) {
	.iv2-contact__privacy-link {
		text-decoration: underline;
	}
}
</style>