<template>
    <div>
        <BaseHeadline :moduleSettings="moduleSettings" />
        <div class="text-left">
            <BaseExpired :date="end" />
            <BaseItemNumber :num="num" :time="time" />
            <p class="iv-paragraph">本アンケートは終了しました。</p>
            <BaseAnnotation />
        </div>
    </div>
</template>

<script>
import BaseHeadline from '../items/BaseHeadline';
import BaseExpired from '../items/BaseExpired';
import BaseItemNumber from '../items/BaseItemNumber';
import BaseAnnotation from '../items/BaseAnnotation';

export default {
    components: {
        BaseHeadline,
        BaseExpired,
        BaseItemNumber,
        BaseAnnotation
    },

    props: {
        forms: Object,
        start: String,
        end: String,
        num: Number,
        time: Number,
        moduleSettings: Object
    }
};
</script>

<style lang="scss"></style>
