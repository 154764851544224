<template>
	<div>
		<article class="iv2-article">
			<Error404
				v-if="openType === 0"
				:public-lang="publicLang"
			/>

			<div v-else-if="openType === 1">
				<BaseTitle
					:items="items"
					:public-lang="publicLang"
				/>
				<div v-if="contact.form_type == 'gen'">
					<LayoutFormBasic
						:contact="contact"
						:language="language"
						:public-lang="publicLang"
					/>
				</div>
				<div v-else-if="contact.form_type == 'biz'">
					<LayoutFormBusiness
						:contact="contact"
						:language="language"
						:public-lang="publicLang"
					/>
				</div>
				<div v-else-if="contact.form_type == 'cus'">
					<LayoutFormCustomer
						:contact="contact"
						:language="language"
						:public-lang="publicLang"
					/>
				</div>
			</div>
		</article>
		<div v-if="loading">
			<BaseLoading />
		</div>
	</div>
</template>

<script>
import BaseTitle from '@/components/items/BaseTitle.vue';
import LayoutFormBasic from './components/LayoutFormBasic.vue';
import LayoutFormBusiness from './components/LayoutFormBusiness.vue';
import LayoutFormCustomer from './components/LayoutFormCustomer.vue';
import BaseLoading from '@/components/items/BaseLoading.vue';
import Error404 from '@/components/systems/Page404.vue';
import Meta from '@/mixins/MetaSettable';
import Preview from '@/mixins/Preview';

export default {
	components: {
		BaseTitle,
		LayoutFormBasic,
		LayoutFormBusiness,
		LayoutFormCustomer,
		BaseLoading,
		Error404
	},

	mixins: [Meta, Preview],

	props: {
		language: String,
		publicLang: Object
	},

	data() {
		return {
			contact: [],
			items: {
				title: '',
				subtitle: '',
				header_type: 'text_only'
			},
			openType: '',
			loading: true
		}
	},

	mounted() {
		this.axios.get('/contacts', {
			params: {
				hl: this.language,
			}
		}).then((response) => {
			this.contact = response.data;
			this.items.title = response.data.title;
			this.items.subtitle = response.data.subtitle;
			if (this.$route.query.mode == 'preview') {
				this.openType = 1;
			} else {
				this.openType = response.data.open_type;
			}
		}).catch(() => {
			this.openType = 0;
		}).finally(() => {
			this.loading = false;
		});
	}
}
</script>

<style lang="scss"></style>