<template>
	<div class="iv2-coupon">
		<p class="text-center m-0">
			<router-link
				to="/coupon"
				class="iv2-coupon__btn theme__main-btn mr-sm-3 mt-3"
			>{{ publicLang.coupon.list_button }}</router-link>
			<button
				v-if="coupon.usage_begin_at && !coupon.limit_over"
				@click="openModal"
				class="iv2-coupon__btn theme__sub-btn ml-sm-3 mt-3"
			>{{ publicLang.coupon.button }}</button>
		</p>
		<div class="text-center">
			<p
				v-if="coupon.usage_begin_at && !coupon.limit_over"
				class="iv2-coupon__text"
			>{{ coupon.usage_end_at }} {{ publicLang.coupon.limit }}</p>
			<p
				v-else-if="coupon.usage_begin_at && coupon.limit_over"
				class="iv2-coupon__text text-danger"
			>{{ publicLang.coupon.limit_over }}</p>
			<p
				v-else
				class="iv2-coupon__text"
			>{{ publicLang.coupon.limit_none }}</p>
		</div>
		<LayoutSlot
			@close="closeModal"
			v-if="modal"
		>
			<!-- default スロットコンテンツ -->
			<div v-if="couponActive == false">
				<p class="iv2-coupon__modal-text text-pre">{{ publicLang.coupon.modal_lead }}</p>
				<small class="iv2-coupon__modal-text-small text-pre">{{ publicLang.coupon.modal_summary }}</small>
			</div>
			<!-- /default -->

			<!-- footer スロットコンテンツ -->
			<template
				slot="footer"
				v-if="couponActive == false"
			>
				<button
					@click="closeModal"
					class="iv2-coupon__sub-btn"
				>{{ publicLang.coupon.disagree }}</button>
				<button
					@click="doAction"
					class="iv2-coupon__sub-btn"
				>{{ publicLang.coupon.agree }}</button>
			</template>
			<!-- /footer -->

			<div
				v-if="couponActive == true"
				class="text-center"
			>
				<button
					@click="closeModal"
					class="iv2-coupon__close-btn"
				></button>
				<div class="iv2-coupon__img">
					<picture>
						<source
							type="image/webp"
							:srcset="coupon.image + publicLang.general.image_webp"
						>
						<img :src="coupon.image">
					</picture>
				</div>
				<p
					v-if="coupon.usage_begin_at"
					class="iv2-coupon__modal-text"
				>{{ coupon.usage_end_at }} {{ publicLang.coupon.limit }}</p>
				<p
					v-else
					class="iv2-coupon__modal-text"
				>{{ publicLang.coupon.limit_none }}</p>
				<p
					@click="closeModal"
					class="iv2-coupon__label"
				>{{ publicLang.coupon.close }}</p>
			</div>
		</LayoutSlot>
	</div>
</template>

<script>
import LayoutSlot from './LayoutSlot.vue';

export default {
	components: {
		LayoutSlot
	},

	props: {
		coupon: Object,
		publicLang: Object
	},

	data() {
		return {
			modal: false,
			couponActive: false,
			environment: ''
		}
	},

	methods: {
		openModal() {
			this.modal = true;
		},

		closeModal() {
			this.modal = false;
		},

		doAction() {
			this.couponActive = true;
			const siteId = document.head.querySelector("[name=sid][content]").content;
			const id = siteId;
			const key = 'coupon';
			if (process.env.NODE_ENV === 'production') {
				this.environment = 'production';
			} else {
				this.environment = 'dev';
			}
			window.ivSendActivities(id, {user_agent: navigator.userAgent.toLowerCase(),provide_name: key,provide_id: this.coupon.id}, this.environment);
		}
	}
}
</script>

<style lang="scss">
.iv2-coupon {
	padding-bottom: 100px;

	.iv2-coupon__btn {
		cursor: pointer;
		text-align: center;
		display: inline-block;
		border: 0;
		font-size: 18px;
		letter-spacing: 2px;
		width: 300px;
		max-width: 100%;
		padding: 15px 0;
		margin: 10px 13px;
		border-radius: 0;
		text-decoration: none;
	}

	.iv2-coupon__sub-btn {
		cursor: pointer;
		text-align: center;
		display: inline-block;
		margin-top: 30px;
		margin-left: 20px !important;
		margin-right: 20px !important;
		color: #fff;
		background-color: transparent;
		border: 2px solid #fff;
		border-radius: 30px;
		width: 200px;
		font-size: 1.5rem;
	}

	.iv2-coupon__img {
		background-color: #fff;
		padding: 25px;
		margin-bottom: 50px;
		width: 600px;
		max-width: 100%;
	}

	.iv2-coupon__close-btn {
		position: absolute;
		right: 0px;
		top: 0px;
		height: 40px;
		width: 40px;
		background-color: #000;
		border: none;
		cursor: pointer;
		outline: none;
		padding: 0;
		border-radius: 30px;

		&:before,
		&:after {
			content: "";
			background-color: #fff;
			height: 3px;
			width: 20px;
			display: block;
			position: absolute;
			right: 10px;
			top: 19px;
		}

		&:before {
			transform: rotate(-45deg);
		}

		&:after {
			transform: rotate(45deg);
		}
	}

	.iv2-coupon__label {
		cursor: pointer;
		text-align: center;
		display: inline-block;
		color: #fff;
		background-color: transparent;
		border: 2px solid #fff;
		border-radius: 30px;
		width: 200px;
		font-size: 1.5rem;
		margin: 0;
	}

	.iv2-coupon__text {
		font-size: 18px;
	}

	.iv2-coupon__modal-text {
		text-align: center;
		font-size: 2rem;
		color: #fff;
	}

	.iv2-coupon__modal-text-small {
		display: block;
		text-align: center;
		font-size: 1rem;
		color: #fff;
	}
}

@media (max-width: 767px) {
	.iv2-coupon {
		.iv2-coupon__text {
			font-size: 14px;
		}

		.iv2-coupon__modal-text {
			font-size: 1.2rem;
		}

		.iv2-coupon__sub-btn {
			font-size: 1.2rem;
		}

		.iv2-coupon__img {
			padding: 15px;
			> img {
				max-width: 300px;
			}
		}
	}
}
</style>