<template>
	<div class="container-fluid">
		<div class="row clearfix">
			<div class="column full">
				<div class="spacer height-60"></div>
			</div>
			<div
				v-for="item in items"
				:key="item.id"
				:class="indexLayout(parentItems.index_layout)"
			>
				<router-link
					:to="'/' + parentItems.segment_name + '/' + item.segment_name + lang + query"
					class="iv2-freepage-item__link"
				>
					<div
						v-if="parentItems.index_layout === 'add_thumbnail'"
						class="iv2-freepage-item__img fit-img"
					>
						<picture>
							<source
								type="image/webp"
								:srcset="item.thumbnail + publicLang.general.image_webp">
							<img
								class="m-0"
								:src="item.thumbnail"
								:alt="'「' + item.title + '」' + publicLang.page.freepage_alt"
							>
						</picture>
					</div>
					<h3 class="iv2-freepage-item__title">{{ item.title }}</h3>
					<p class="iv2-freepage-item__summary">{{ item.content }}</p>
					<p class="iv2-freepage-item__more">
						<i class="fas fa-chevron-right"></i> {{ publicLang.page.more }}
					</p>
				</router-link>
			</div>
			<div class="column full">
				<div class="spacer height-60"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		parentItems: Object,
		items: Array,
		publicLang: Object
	},

	data() {
		return {
			query: '',
			lang: '',
		}
	},

	mounted() {
		this.gridHeight();
		if (this.$route.query.mode == 'preview') {
			this.query = '?mode=preview';
		}
		if (this.$route.query.hl) {
			this.lang = '?hl=' + this.$route.query.hl;
		}
	},

	updated() {
		this.gridHeight();
	},

	methods: {
		gridHeight() {
			let title = document.getElementsByClassName('iv2-freepage-item__title');
			let desc = document.getElementsByClassName('iv2-freepage-item__summary');
			let titleMaxHeight = 0;
			let descMaxHeight = 0;
			let titleArray = new Array;
			let descArray = new Array;

			Array.prototype.forEach.call(title, function(el) {
				el.style.height = '';
				titleArray.push(el.clientHeight);
			});
			Array.prototype.forEach.call(desc, function(el) {
				el.style.height = '';
				descArray.push(el.clientHeight);
			});

			titleMaxHeight = Math.max.apply(null, titleArray);
			descMaxHeight = Math.max.apply(null, descArray);

			Array.prototype.forEach.call(title, function(el) {
				el.style.height = titleMaxHeight + 'px';
			});
			Array.prototype.forEach.call(desc, function(el) {
				el.style.height = descMaxHeight + 'px';
			});
		},

		/** インデックスのレイアウト調整のクラス付与 */
		indexLayout(type) {
			return (type === 'add_thumbnail')
				? 'iv2-freepage-item__thumb-layout iv2-freepage-item mb-5 col-md-6 col-xl-4'
				: 'iv2-freepage-item__text-layout iv2-freepage-item mb-3 col-md-6 col-xl-4';
		}
	}
}
</script>

<style lang="scss">
.iv2-freepage-item .iv2-freepage-item__link {
	display: block;
	text-decoration: none;

	&:hover .iv2-freepage-item__img {
		opacity: 0.7;
	}

	.iv2-freepage-item__img {
		height: 200px;
		-webkit-transition: 0.3s;
		transition: 0.3s;
	}

	.iv2-freepage-item__more {
		margin-top: 10px;
		font-size: 0.7rem;
		font-weight: normal;
	}

	.iv2-freepage-item__title {
		font-weight: bold;
	}

	.iv2-freepage-item__summary {
		margin-bottom: 0;
		padding-bottom: 0;
		font-weight: normal;
	}
}

.iv2-freepage-item__thumb-layout {
	.iv2-freepage-item__title {
		margin-top: 15px;
		font-size: 1.1rem;
	}

	.iv2-freepage-item__summary {
		font-size: 1.0rem;
	}
}

.iv2-freepage-item__text-layout {
	.iv2-freepage-item__title {
		font-size: 1.3rem;
	}

	.iv2-freepage-item__summary {
		font-size: 0.9rem;
	}

	.iv2-freepage-item__more {
		padding-bottom: 25px;
		border-bottom: 1px solid #f0f0f0;
	}
}
</style>