<template>
    <div>
        <BaseHeadline :moduleSettings="moduleSettings" />

        <div
            v-show="!codeSearch"
            class="text-left"
        >

            <BaseExpired :date="end" />
            <BaseItemNumber :num="num" :time="time" />
            <p class="iv-paragraph">「次へ」を押してアンケートにお進みください。</p>
            <BaseAnnotation />
            <hr>
            <div class="row justify-content-center">
                <div class="col-8 col-md-4">
                    <button
                        @click="changeMode(true)"
                        v-text="'次へ'"
                        type="button"
                        class="btn btn-primary btn-block"
                    >次へ</button>
                </div>
            </div>

        </div>

        <div
            v-show="codeSearch"
            class="text-left"
        >

            <div class="form-group">
                <h2 class="iv-paragraph">店舗コード入力</h2>
                <p class="iv-paragraph">勤務している店舗の店舗コードを4桁の半角数字で入力してください。</p>
                <div class="row">
                    <div class="col-8 mb-2">
                        <input v-model="code" type="text" class="form-control form-control-lg">
                    </div>
                    <div class="col-4 mb-2">
                        <button @click="searchCode()" type="button" class="btn btn-sm btn-primary py-2">検索</button>
                    </div>
                </div>
                <div v-if="searchErr === false && searchResult != ''">
                    <p class="iv-paragraph">あなたの勤務している店舗は<br>「<strong class="font-weight-bold">{{ searchResult }}</strong>」です。</p>
                    <p class="iv-paragraph">これでよろしければ、「次へ」を押してアンケートへお進みください。</p>
                </div>
                <div v-else-if="searchErr === true">
                    <p class="text-danger iv-paragraph">入力した店舗コードに該当する店舗が見つかりませんでした。</p>
                </div>
                <hr>
                <div class="row justify-content-center">
                    <div class="col-8 col-md-4">
                        <a
                            :href="ref"
                            v-if="searchErr === false && searchResult != ''"
                            v-text="'次へ'"
                            class="btn btn-primary btn-block"
                        ></a>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import BaseHeadline from '../items/BaseHeadline.vue';
import BaseExpired from '../items/BaseExpired.vue';
import BaseItemNumber from '../items/BaseItemNumber.vue';
import BaseAnnotation from '../items/BaseAnnotation.vue';

export default {
    components: {
        BaseHeadline,
        BaseExpired,
        BaseItemNumber,
        BaseAnnotation
    },

    props: {
        forms: Object,
        start: String,
        end: String,
        num: Number,
        time: Number,
        moduleSettings: Object
    },

    data() {
        return {
            code: '',
            codeSearch: false,
            searchResult: '',
            searchErr: false,
            ref: '#'
        };
    },

    methods: {
        changeMode(flag) {
            this.codeSearch = flag;
        },

        searchCode() {
            const self = this;
            this.axios({
                url: this.moduleSettings.codePath,
                baseURL: process.env.VUE_APP_STORAGE_URL
            }).then((response) => {
                if (this.code != undefined && this.code != '' && response != undefined && response.data != undefined) {
                    if (Object.keys(response.data).indexOf(this.code) !== -1) {
                        // 検索結果の準備
                        this.searchResult = response.data[this.code];
                        this.searchErr = false;

                        // リンク値生成
                        this.ref = this.moduleSettings.baseFormUrl
                            + process.env.VUE_APP_ID + '/'
                            + self.moduleSettings.formId + '/'
                            + 'form/?params[' + self.moduleSettings.codeId + ']=' + this.code
                            + '&params[' + self.moduleSettings.nameId + ']=' + this.searchResult
                            + '';
                    } else {
                        this.searchErr = true;
                    }
                } else {
                    this.searchErr = true;
                }
            }).catch(() => {
                // ...
            }).finally(() => {
                // ...
            });
        }
    }
};
</script>

<style lang="scss">
    .btn.btn-primary {
        padding: 0.75rem 1.25rem;
        color: #fff;
        background-color: #1f56b7;
        border-color: #1f56b7;
        box-shadow: 0px 2px 5px #bbb;
        white-space: nowrap;

        &:hover {
            background-color: #2d6cdc;
            border-color: #2d6cdc;
        }
    }
</style>
