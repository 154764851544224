<template>
	<section
		v-if="displayRss"
		:class="{ 'py-0': responsive === true }"
		class="iv2-rss theme__sub-bg"
	>
		<div class="container">
			<div class="row clearfix">
				<div class="column full text-center">
					<div class="spacer height-80"></div>
					<p class="m-0">{{ publicLang.general.rss }}</p>
					<h3>
						<a
							:href="blog.link"
							class="theme__heading-text"
							target="_blank"
							rel="noopener"
						>{{ blog.name }}</a>
					</h3>
					<div class="spacer height-40"></div>
				</div>
				<div
					v-for="item in blog.articles"
					:key="item.id"
					class="iv2-rss__item col-md-6"
				>
					<a
						:href="item.url"
						class="iv2-rss__item--link"
						target="_blank"
						rel="noopener"
					>
						<div class="iv2-rss__items--block theme-f__home-rss__items column full">
							<small>{{ item.created_at }}</small>
							<h4 class="iv2-rss__items-title">{{ item.title }}</h4>
							<p class="iv2-rss__items-summary mt-0 mb-2">{{ item.discription }}</p>
						</div>
					</a>
				</div>
				<div class="column full">
					<div class="spacer height-80"></div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	props: {
		items: Object,
		news: Object,
		blog: Object,
		publicLang: Object,
		showDisplay: String,
		responsive: Boolean
	},

	data() {
		return {
			displayRss: '',
			viewReturn: ''
		}
	},
	
	created() {
		if (this.blog.is_linked_blog === 0 || this.blog.articles.length === 0) {
			this.displayRss = false;
		} else {
			this.displayRss = true;
		}
	},

	mounted() {
		if (this.responsive === false) {
			this.changeLayout();
		}
	},

	updated() {
		if (this.showDisplay == 'rss' && this.responsive === false) {
			this.changeLayout();
		}
	},

	methods: {
		changeLayout() {
			if (this.news.data.length > 0 && this.items.display_news_number > 0) {
				this.viewReturn = 'news';
			} else {
				this.viewReturn = 'pattern';
			}
			this.$emit('view-return', this.viewReturn);
		}
	}
}
</script>

<style lang="scss">
.iv2-rss {
	padding-top: 80px;

	.iv2-rss__item {
		margin: 15px 0;

		.iv2-rss__item--link {
			text-decoration: none;
			
			.iv2-rss__items--block {
				padding: 0.5rem 1rem;
				-webkit-box-shadow: 0px 0px 20px rgba(100, 100, 100, 0.1);
				box-shadow: 0px 0px 20px rgba(100, 100, 100, 0.1);
				height: 100%;

				&:hover {
					transition: 0.3s;
					transform: scale(1.01);
				}

				.iv2-rss__items-title {
					font-size: 22px;
				}

				.iv2-rss__items-summary {
					font-size: 16px;
					line-height: 1.5;
				}
			}
		}
	}
}
</style>