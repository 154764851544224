<template>
	<div
		v-if="lastPage > 1"
		class="container"
	>
		<div class="row clearfix">
			<div class="column full text-center">
				<paginate
					:page-count="lastPage"
					:page-range="3"
					:margin-pages="2"
					:click-handler="pagination"
					:prev-text="'＜'"
					:next-text="'＞'"
					:container-class="'pagination d-inline-flex'"
					:page-class="'page-item theme__pagination'"
					:page-link-class="'page-link'">
				</paginate>
			</div>
			<div class="column full">
				<div class="spacer height-100"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		lastPage: Number
	},

	methods: {
		pagination (page) {
			this.$emit('pagination', page);
		}
	}
}
</script>

<style lang="scss"></style>