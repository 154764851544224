<template>
	<article class="iv2-article">
		<transition
			mode="out-in"
			:name="animation"
			appear
		>
			<div v-if="pageActive">
				<Error404
					v-if="openType === 0"
					:public-lang="publicLang"
				/>

				<div v-else-if="openType === 1">
					<BaseTitle
						:items="items"
						:public-lang="publicLang"
					/>
					<BaseBreadcrumb :items="items.breadcrumb" />
					<FreepageIndex
						v-if="indexType === 'top'"
						:parentItems="items"
						:items="items.children"
						:public-lang="publicLang"
					/>
					<LayoutPattern :items="items" />
					<FreepageIndex
						v-if="indexType === 'bottom'"
						:parentItems="items"
						:items="items.children"
						:public-lang="publicLang"
					/>
				</div>
			</div>
		</transition>

		<div v-if="loading">
			<BaseLoading />
		</div>
	</article>
</template>

<script>
import BaseTitle from '@/components/items/BaseTitle.vue';
import BaseBreadcrumb from '@/components/items/BaseBreadcrumb.vue';
import LayoutPattern from './components/LayoutPattern.vue';
import FreepageIndex from './components/FreepageIndex.vue';
import BaseLoading from '@/components/items/BaseLoading.vue';
import Error404 from '@/components/systems/Page404.vue';
import Meta from '@/mixins/MetaSettable';
import Hash from '@/mixins/AnchorLink';
import Preview from '@/mixins/Preview';

export default {
	components: {
		BaseTitle,
		BaseBreadcrumb,
		LayoutPattern,
		FreepageIndex,
		BaseLoading,
		Error404
	},

	mixins: [Meta, Hash, Preview],

	props: {
		animation: String,
		language: String,
		publicLang: Object
	},

	data() {
		return {
			items: [],
			openType: '',
			indexType: '',
			loading: true,
			pageActive: false
		}
	},

	watch: {
		$route(to, from) {
			if (to.path !== from.path) {
				this.loading = true;
				this.pageActive = false;
				this.load();
			}
		}
	},

	mounted() {
		this.load();
	},

	methods: {
		load() {
			if (this.$route.query.mode == 'preview' && this.$route.query.api_token) {
				this.axios.get('/preview/pages' + this.$route.path + '/show', {
					headers: {
						Authorization: `Bearer ${this.$cookies.get('preview-token')}`,
					},
					params: {
						hl: this.language,
					}
				}).then((response) => {
					this.items = response.data.response;
					this.openType = response.data.response.open_type;
					this.indexType = response.data.response.index_type;
				}).catch(() => {
					this.openType = 0;
				}).finally(() => {
					this.loading = false;
					this.pageActive = true;
				});
			} else {
				this.axios.get('/pages' + this.$route.path + '/show', {
					params: {
						hl: this.language,
					}
				}).then((response) => {
					this.items = response.data.response;
					this.indexType = response.data.response.index_type;
					if (this.$route.query.mode == 'preview') {
						this.openType = 1;
					} else {
						this.openType = response.data.response.open_type;
					}
				}).catch(() => {
					this.openType = 0;
				}).finally(() => {
					this.loading = false;
					this.pageActive = true;
				});
			} 
		}
	}
}
</script>

<style lang="scss"></style>