<template>
	<div class="iv2-error__503">
		<div class="iv2-error__503-block">
			<div class="container">
				<h1 class="iv2-error__503-number">{{ publicLang.system.error503.status }}</h1>
				<h2 class="iv2-error__503-lead">{{ publicLang.system.error503.lead }}</h2>
				<p class="iv2-error__503-summary text-pre">{{ publicLang.system.error503.summary }}</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		publicLang: Object
	},

	created() {
		document
			.querySelector('meta[name="render:status_code"]')
			.setAttribute('content', 503);
		document
			.querySelector('meta[name="robots"]')
			.setAttribute('content', 'noindex');
	}
}
</script>

<style lang="scss">
.iv2-error__503 {
	background-color: #efefef;
	height: 100vh;
	position: relative;

	.iv2-error__503-block {
		text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;

		.iv2-error__503-number {
			font-size: 5.5rem;
		}

		.iv2-error__503-lead {
			font-size: 2rem;
			font-weight: 600;
			padding: 2rem 1rem;
			margin: 0;
		}

		.iv2-error__503-summary {
			margin: 0;
		}
	}
}

@media (max-width: 767px) {
	.iv2-error__503 {
		.iv2-error__503-block {
			.iv2-error__503-number {
				font-size: 4rem;
			}

			.iv2-error__503-lead {
				font-size: 1.5rem;
			}
		}
	}
}
</style>