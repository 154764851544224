<template>
	<div>
		<article
			class="iv2-article"
			v-if="pageActive"
		>
			<transition
				mode="out-in"
				:name="animationName"
			>
				<LayoutHero
					v-if="showDisplay == 'hero' || responsive === true"
					:items="items"
					:news="news"
					:blog="blog"
					:organization="organization"
					:public-lang="publicLang"
					:show-display="showDisplay"
					:responsive="responsive"
					@view-more="nextLayout"
				/>
			</transition>
			<transition
				mode="out-in"
				:name="animationName"
			>
				<LayoutPattern
					v-if="showDisplay == 'pattern' || responsive === true"
					:items="items"
					:news="news"
					:blog="blog"
					:show-display="showDisplay"
					:responsive="responsive"
					@view-more="nextLayout"
					@view-return="prevLayout"
				/>
			</transition>
			<transition
				mode="out-in"
				:name="animationName"
			>
				<LayoutNews
					v-if="showDisplay == 'news' || responsive === true"
					:items="items"
					:news="news"
					:blog="blog"
					:language="language"
					:public-lang="publicLang"
					:theme-lang="themeLang"
					:show-display="showDisplay"
					:responsive="responsive"
					@view-more="nextLayout"
					@view-return="prevLayout"
				/>
			</transition>
			<transition
				mode="out-in"
				:name="animationName"
			>
				<LayoutRss
					v-if="showDisplay == 'rss' || responsive === true"
					:items="items"
					:news="news"
					:blog="blog"
					:public-lang="publicLang"
					:show-display="showDisplay"
					:responsive="responsive"
					@view-return="prevLayout"
				/>
			</transition>

			<TheBanner
				v-if="showDisplay === lastContent || responsive === true"
				:banner="banner"
				:public-lang="publicLang"
			/>

			<TheFooter
				v-if="showDisplay === lastContent || responsive === true"
				:footer="footer"
				:organization="organization"
				:information="information"
				:translation="translation"
				:public-lang="publicLang"
				:theme-lang="themeLang"
				:count="count.footer"
			/>
			<HomeViewReturn
				v-if="responsive === false"
				:class="{ scroll: showDisplay !== 'hero' && scrollY <= 0 }"
				:viewReturn="viewReturn"
				@change-layout="activePrev"
			/>
			<HomeViewMore
				v-if="responsive === false"
				:class="{ scroll: showDisplay !== lastContent && overHeight || showDisplay == 'hero' }"
				:viewMore="viewMore"
				:theme-lang="themeLang"
				@change-layout="activeNext"
			/>
		</article>
		<div v-if="loading">
			<BaseLoading />
		</div>
	</div>
</template>

<script>
import LayoutHero from "./components/LayoutHero.vue";
import LayoutPattern from "./components/LayoutPattern.vue";
import LayoutNews from "./components/LayoutNews.vue";
import LayoutRss from "./components/LayoutRss.vue";
import TheBanner from '@/components/global/TheBanner.vue';
import TheFooter from '@/components/global/TheFooter.vue';
import HomeViewMore from "./components/HomeViewMore.vue";
import HomeViewReturn from "./components/HomeViewReturn.vue";
import BaseLoading from "@/components/items/BaseLoading.vue";
import Meta from "@/mixins/MetaSettable";
import Hash from "@/mixins/AnchorLink";
import Preview from "@/mixins/Preview";

export default {
	components: {
		LayoutHero,
		LayoutPattern,
		LayoutNews,
		LayoutRss,
		TheBanner,
		TheFooter,
		HomeViewMore,
		HomeViewReturn,
		BaseLoading,
	},

	mixins: [Meta, Hash, Preview],

	props: {
		organization: Object,
		information: Object,
		blog: Object,
		banner: Object,
		footer: Array,
		translation: Object,
		language: String,
		publicLang: Object,
		themeLang: Object,
		count: Object
	},

	data() {
		return {
			items: [],
			news: [],
			pageActive: false,
			loading: true,
			scrollY: 0,
			showDisplay: '',
			lastContent: null,
			viewMore: '',
			viewReturn: '',
			animationName: '',
			overHeight: true,
			height: '',
			responsive: ''
		}
	},

	created() {
		if (this.$route.query.mode == 'preview' && this.$route.query.api_token) {
			this.axios.get('/preview/top_page', {
				headers: {
					Authorization: `Bearer ${this.$cookies.get('preview-token')}`,
				},
				params: {
					hl: this.language,
				}
			}).then((response) => {
				this.items = response.data.response;
				this.getNews();
			}).finally(() => {
				this.loading = false;
				this.pageActive = true;
			});
		} else {
			this.axios.get('/top_page', {
				params: {
					hl: this.language,
				}
			}).then((response) => {
				this.items = response.data.response;
				this.getNews();
			}).finally(() => {
				this.loading = false;
				this.pageActive = true;
			});
		}
		window.addEventListener('scroll', this.handleScroll);
	},

	methods: {
		// 次のセクションを取得
		nextLayout(val, el) {
			this.viewMore = val;
			this.height = el;
			this.getScroll();
		},

		// 前のセクションを取得
		prevLayout(val) {
			this.viewReturn = val;
		},

		async activeNext(val) {
			await (this.animationName = 'bottom-in-slide');
			this.showDisplay = val;
			window.scrollTo({
				top: 0,
			});
		},

		async activePrev(val) {
			await (this.animationName = 'top-in-slide');
			this.showDisplay = val;
			window.scrollTo({
				top: 0,
			});
		},

		handleScroll() {
			this.scrollY = window.scrollY;
		},

		// セクション移動の際スクロールが発生するかを定義
		getScroll() {
			if (this.scrollY + window.innerHeight >= this.height) {
				this.overHeight = true;
			} else {
				this.overHeight = false;
			}
		},

		getNews() {
			this.axios.get('/news', {
				params: {
					hl: this.language,
				}
			}).then(({data}) => {
				this.news = data.response;

				// 初回表示のセクションを定義
				this.showDisplay = 'hero';
				if (window.innerWidth < 1199 && this.items.display_type == 'responsive') {
					this.responsive = true;
				} else {
					this.responsive = false;
				}

				// 最後のセクションを定義
				if (this.blog.is_linked_blog == 1 && this.blog.articles.length > 0) {
					this.lastContent = 'rss';
				} else if (this.news.data.length > 0 && this.items.display_news_number > 0) {
					this.lastContent = 'news';
				} else {
					this.lastContent = 'pattern';
				}
			});
		}
	},

	updated() {
		this.getScroll();
	}
}
</script>

<style lang="scss"></style>