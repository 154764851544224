<template>
	<div>
		<article
			class="iv2-article"
			v-if="pageActive"
		>
			<div v-if="indexOpenType === 1">
				<BaseTitle
					:items="items"
					:public-lang="publicLang"
				/>

				<!-- 記事一覧 -->
				<LayoutList
					:news="news"
					:categories="categories"
					:archives="archives"
					:explanation="explanation"
					:public-lang="publicLang"
					:theme-lang="themeLang"
					@click-change="clickChange"
					@select-change="selectChange"
					@select-archive="selectArchive"
				/>

				<!-- ページネーション -->
				<BasePagination
					v-if="news.length > 0"
					:last-page="lastPage"
					@pagination="pagination"
				/>
			</div>

			<Error404
				v-else-if="indexOpenType === 0"
				:public-lang="publicLang"
			/>
		</article>

		<div v-if="loading">
			<BaseLoading />
		</div>
	</div>
</template>

<script>
import BaseTitle from '@/components/items/BaseTitle.vue';
import LayoutList from './components/LayoutList.vue';
import BaseLoading from '@/components/items/BaseLoading.vue';
import BasePagination from '@/components/items/BasePagination.vue';
import Error404 from '@/components/systems/Page404.vue';
import Meta from '@/mixins/MetaSettable';

export default {
	components: {
		BaseTitle,
		LayoutList,
		BaseLoading,
		BasePagination,
		Error404
	},

	mixins: [Meta],

	props: {
		language: String,
		publicLang: Object,
		themeLang: Object
	},

	data() {
		return {
			news: [],
			items: {
				title: '',
				subtitle: '',
				header_type: 'text_only'
			},
			categories: [],
			archives: [],
			lastPage: 1,
			explanation: '',
			indexOpenType: '',
			pageActive: false,
			loading: false,
			sortPage: 1,
			sortId: 0,
			sortArchive: 0
		}
	},

	created() {
		this.axios.get('/news/categories', {
			params: {
				hl: this.language,
			}
		}).then(({data}) => {
			this.categories = data.response;
		})
		let year = 2020;
		let nowDate = new Date().getFullYear();
		let tmp = nowDate - year + 1;
		for (let i = 0; i < tmp; i++) {
			this.archives.push(nowDate - i);
		}
	},

	mounted() {
		this.load();
	},

	methods: {
		load() {
			this.loading = true;
			this.axios.get('/news', {
				params: {
					hl: this.language,
					page: this.sortPage,
					category_id: this.sortId,
					year: this.sortArchive
				}
			}).then(({data}) => {
				this.news = data.response.data;
				this.items.title = data.response.title;
				this.items.subtitle = data.response.subtitle;
				this.lastPage = data.response.last_page;
				this.explanation = data.response.explanation;
				if (this.$route.query.mode == 'preview') {
					this.indexOpenType = 1;
				} else {
					this.indexOpenType = data.response.open_type;
				}
			}).catch(() => {
				this.indexOpenType = 0;
			}).finally(() => {
				this.loading = false;
				this.pageActive = true;
				window.scrollTo({
					top: 0,
				});
			});
		},

		pagination(page) {
			if (page >= 1 && page <= this.lastPage) {
				this.sortPage = page;
				this.load();
			}
		},

		clickChange(id) {
			this.sortPage = 1;
			this.sortId = id;
			this.load();
		},

		selectChange(event) {
			this.sortPage = 1;
			this.sortId = event.target.value;
			this.load();
		},

		selectArchive(event) {
			this.sortPage = 1;
			this.sortArchive = event.target.value;
			this.load();
		}
	}
}
</script>

<style lang="scss"></style>