<template>
	<aside class="iv2-extra-sns mb-5">
		<div class="iv2-extra-sns-nav">
			<ul class="iv2-extra-sns-nav-list list-unstyled m-0">
				<li
					v-if="sns.is_linked_facebook == 1"
					class="iv2-extra-sns-nav-item text-center"
				>
					<a
						:href="sns.facebook_url"
						class="iv2-extra-sns-nav-link theme-f__extra-sns-link"
						target="_blank"
						rel="noopener"
					>
						<i class="iv2-extra-sns-nav-item-icon fab fa-facebook-f"></i>
					</a>
				</li>
				<li
					v-if="sns.is_linked_instagram == 1"
					class="iv2-extra-sns-nav-item text-center"
				>
					<a
						:href="sns.instagram_url"
						class="iv2-extra-sns-nav-link theme-f__extra-sns-link"
						target="_blank"
						rel="noopener"
					>
						<i class="iv2-extra-sns-nav-item-icon fab fa-instagram"></i>
					</a>
				</li>
				<li
					v-if="sns.is_linked_twitter == 1"
					class="iv2-extra-sns-nav-item text-center"
				>
					<a
						:href="sns.twitter_url"
						class="iv2-extra-sns-nav-link theme-f__extra-sns-link"
						target="_blank"
						rel="noopener"
					>
						<i class="iv2-extra-sns-nav-item-icon fab fa-twitter"></i>
					</a>
				</li>
				<li
					v-if="sns.is_linked_line == 1"
					class="iv2-extra-sns-nav-item text-center"
				>
					<a
						:href="sns.line_url"
						class="iv2-extra-sns-nav-link theme-f__extra-sns-link"
						target="_blank"
						rel="noopener"
					>
						<i class="iv2-extra-sns-nav-item-icon fab fa-line"></i>
					</a>
				</li>
				<!-- <li
					v-if="rss.is_linked_blog == 1"
					class="iv2-extra-sns-nav-item text-center"
				>
					<a
						:href="rss.url"
						class="iv2-extra-sns-nav-link theme-f__extra-sns-link"
						target="_blank"
						rel="noopener"
					>
						<i class="iv2-extra-sns-nav-item-icon fas fa-rss"></i>
					</a>
				</li> -->
			</ul>
		</div>
	</aside>
</template>

<script>
export default {
	props: {
		sns: Object,
		rss: Object
	}
}
</script>

<style lang="scss"></style>