import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import Paginate from 'vuejs-paginate';
import VueCookies from 'vue-cookies';
import VueTooltip from 'v-tooltip';
import "./registerServiceWorker";

/* cssファイル読み込み */
import "./assets/css/iv2-theme-f-component.css";

Vue.config.productionTip = false;
Vue.use(VueCookies);
Vue.use(VueTooltip);
Vue.component('paginate', Paginate);

/* axiosのbaseURL設定 */
Vue.use(VueAxios, axios);
var siteId = document.head.querySelector("[name=sid][content]").content;
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_END_POINT + siteId;

/* service workerの登録 */
if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("/service-worker.js", { scope: "/" })
    .then(function() {
        // console.log("登録に成功しました。 Scope は " + reg.scope);
    })
    .catch(function() {
        // console.log("登録に失敗しました。" + error);
    });
}

new Vue({
    router,
    render: (h) => h(App)
}).$mount("#app");
