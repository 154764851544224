<template>
	<div>
		<div
			v-if="coupon.length == 0"
			class="container"
		>
			<div class="iv2-coupon__items-null row clearfix">
				<div class="column full">
					<div style="padding: 2rem 0;">
						<h4 class="size-28 text-center font-weight-bold">{{ publicLang.coupon.null_title }}</h4>
						<p class="text-center">{{ publicLang.coupon.null_summary }}</p>
					</div>
				</div>
				<div class="column full text-center">
					<BaseTopBtn :public-lang="publicLang" />
				</div>
			</div>
		</div>
		<div v-else>
			<div class="container">
				<div class="spacer height-60"></div>
				<div
					v-if="explanation"
					class="row clearfix"
				>
					<div class="column full">
						<p class="text-pre m-0">{{ explanation }}</p>
						<div class="spacer height-80"></div>
					</div>
				</div>
			</div>

			<div class="theme__sub-bg">
				<div class="container">
					<div class="spacer height-80"></div>
					<div class="row">
						<div
							v-for="item in coupon"
							:key="item.id"
							class="iv2-coupon-item my-4 col-md-6 col-xl-4"
						>
							<router-link
								:to="{ name: 'couponDetail', params: { id: item.id }}"
								class="iv2-coupon-item__link"
							>
								<div class="iv2-coupon-item__img fit-img">
									<picture>
										<source
											type="image/webp"
											:srcset="item.image + publicLang.general.image_webp"
										>
										<img
											class="m-0"
											:src="item.image"
											:alt="'「' + item.title + '」' + publicLang.coupon.alt"
										>
									</picture>
								</div>

								<div class="iv2-coupon-item__data mt-4">
									<small
										v-if="item.usage_begin_at && !item.limit_over"
										class="iv2-coupon-item__date"
									>{{ item.usage_end_at }} {{ publicLang.coupon.limit }}</small>
									<small
										v-else-if="item.usage_begin_at && item.limit_over"
										class="iv2-coupon-item__date text-danger"
									>{{ publicLang.coupon.limit_over }}</small>
									<small
										v-else
										class="iv2-coupon-item__date"
									>{{ publicLang.coupon.limit_none }}</small>
								</div>
								<h3 class="iv2-coupon-item__title">{{ item.title }}</h3>
							</router-link>
						</div>
					</div>
					<div class="spacer height-60"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BaseTopBtn from '@/components/items/BaseTopBtn.vue';

export default {
	components: {
		BaseTopBtn
	},

	props: {
		coupon: Array,
		explanation: String,
		publicLang: Object
	}
}
</script>

<style lang="scss">
.iv2-coupon__items-null {
	padding: 8rem 0;
}

.iv2-coupon-item .iv2-coupon-item__link {
	display: block;
	text-decoration: none;

	&:hover .iv2-coupon-item__img {
		opacity: 0.7;
	}

	.iv2-coupon-item__img {
		height: 200px;
		-webkit-transition: 0.3s;
		transition: 0.3s;
	}

	.iv2-coupon-item__title {
		font-size: 1.1rem;
		font-weight: bold;
	}

	.iv2-coupon-item__new {
		color: #a83535;
	}

	.iv2-coupon-item__label {
		width: 100%;
		text-align: center;
		border: 1px solid;
	}
}
</style>