import axios from "axios";

export default {
    async beforeRouteEnter(to, from, next) {
        // meta.json のオブジェクト取得
        const siteId = document.head.querySelector("[name=sid][content]").content;
        const res = await axios.get(process.env.VUE_APP_STORAGE_URL + siteId + '/meta.json');

        // イニシャライズ
        var pageName = to.name;
        var detail;
        var route;

        /** 子ページを持つmeta情報とそれ以外でパース処理を分ける */

        if (pageName.indexOf('Detail') > -1) {
            // route.json の name プロパティに「Detail」と付く場合（クーポン・ニュースなど）
            detail = res.data.filter(item => item.name == pageName.replace(/Detail/g , ''));
            route = detail[0].items.filter(item => item.id == to.params.id);
        } else if (pageName.indexOf('Freepage') > -1) {
            // route.json の name プロパティに「Freepage」と付く場合（フリーページ）
            detail = res.data.filter(item => item.name == pageName.replace(/Freepage/g , ''));
            route = (detail.length > 0)
                ? detail[0].items.filter(item => item.segment_name == to.params.segment_name)
                : res.data.filter(item => item.name == pageName);
        // customize (meta追加)
        } else if (to.name == 'tsutayaes') {
            next(() => {
                const pageTitle = "スタッフ意識調査"
                document.title = pageTitle;
                document
                    .querySelector("meta[property='og:title']")
                    .setAttribute("content", pageTitle);
                document
                    .querySelector("meta[name='description']")
                    .setAttribute("content", pageTitle);
                document
                    .querySelector("meta[property='og:description']")
                    .setAttribute("content", pageTitle);
                document
                    .querySelector("meta[name='keywords']")
                    .setAttribute("content", pageTitle);
            });
        } else {
            route = res.data.filter(item => item.name == pageName);
        }

        // パースしたmeta情報を格納
        if (route != undefined) {
            var value = route[0];

            next(() => {
                if (to && value != undefined && value.title) {
                    document.title = value.title;
                    document
                        .querySelector('meta[property="og:title"]')
                        .setAttribute('content', value.title);
                }
                if (to && value != undefined && value.description) {
                    document
                        .querySelector('meta[name="description"]')
                        .setAttribute('content', value.description);
                    document
                        .querySelector('meta[property="og:description"]')
                        .setAttribute('content', value.description);
                }
                if (to && value != undefined && value.keywords) {
                    document
                        .querySelector('meta[name="keywords"]')
                        .setAttribute('content', value.keywords);
                }
            });
        }
	}
}