<template>
	<div>
		<div
			v-if="download.length == 0"
			class="container"
		>
			<div class="iv2-download__items-null row clearfix">
				<div class="column full">
					<div style="padding: 2rem 0;">
						<h4 class="size-28 text-center font-weight-bold">{{ publicLang.download.null_title }}</h4>
						<p class="text-center">{{ publicLang.download.null_summary }}</p>
					</div>
				</div>
				<div class="column full text-center">
					<BaseTopBtn :public-lang="publicLang" />
				</div>
			</div>
		</div>
		<div v-else>
			<div class="container">
				<div class="spacer height-60"></div>
				<div
					v-if="explanation"
					class="row clearfix"
				>
					<div class="column full">
						<p class="text-pre m-0">{{ explanation }}</p>
						<div class="spacer height-80"></div>
					</div>
				</div>
			</div>

			<div class="container">
				<div class="row">
					<div
						v-for="item in download"
						:key="item.id"
						class="iv2-download-item mt-4 mb-5 col-md-6 col-xl-4"
					>
						<a
							:href="item.file"
							@click="doAction(item.id)"
							target="_blank"
						>
							<div class="iv2-download-item__thumbnail">
								<picture>
									<source
										type="image/webp"
										:srcset="item.thumbnail + publicLang.general.image_webp"
									>
									<img
										class="iv2-download-item__img h-100 m-0"
										:src="item.thumbnail"
										:alt="'「' + item.title + '」' + publicLang.download.alt"
									>
								</picture>
							</div>
							<h3 class="iv2-download-item__title">{{ item.title }}</h3>
						</a>
						<p class="iv2-download-item__description">{{ item.description }}</p>
						<div class="iv2-download-item__btn">
							<a
								:href="item.file"
								@click="doAction(item.id)"
								target="_blank"
								class="theme__sub-btn is-btn is-btn-ghost2 size-18"
								style="letter-spacing: 2px; max-width: 100%;"
							><i class="fas fa-download mr-2 size-16"></i>{{ publicLang.download.button }}</a>
						</div>
					</div>
				</div>
				<div class="spacer height-60"></div>
			</div>
		</div>
	</div>
</template>

<script>
import BaseTopBtn from '@/components/items/BaseTopBtn.vue';

export default {
	components: {
		BaseTopBtn
	},

	props: {
		download: Array,
		explanation: String,
		publicLang: Object
	},

	data() {
		return {
			environment: ''
		}
	},

	mounted() {
		this.gridHeight();
	},

	updated() {
		this.gridHeight();
	},

	methods: {
		gridHeight() {
			let title = document.getElementsByClassName('iv2-download-item__title');
			let desc = document.getElementsByClassName('iv2-download-item__description');
			let titleMaxHeight = 0;
			let descMaxHeight = 0;
			let titleArray = new Array;
			let descArray = new Array;

			Array.prototype.forEach.call(title, function(el) {
				el.style.height = '';
				titleArray.push(el.clientHeight);
			});
			Array.prototype.forEach.call(desc, function(el) {
				el.style.height = '';
				descArray.push(el.clientHeight);
			});

			titleMaxHeight = Math.max.apply(null, titleArray);
			descMaxHeight = Math.max.apply(null, descArray);

			Array.prototype.forEach.call(title, function(el) {
				el.style.height = titleMaxHeight + 'px';
			});
			Array.prototype.forEach.call(desc, function(el) {
				el.style.height = descMaxHeight + 'px';
			});
		},

		doAction(val) {
			const siteId = document.head.querySelector("[name=sid][content]").content;
			const id = siteId;
			const key = 'download';
			if (process.env.NODE_ENV === 'production') {
				this.environment = 'production';
			} else {
				this.environment = 'dev';
			}
			window.ivSendActivities(id, {user_agent: navigator.userAgent.toLowerCase(),provide_name: key,provide_id: val}, this.environment);
		}
	}
}
</script>

<style lang="scss">
.iv2-download__items-null {
	padding: 8rem 0;
}

.iv2-download-item {
	.iv2-download-item__thumbnail {
		height: 200px;
		-webkit-transition: 0.3s;
		transition: 0.3s;
		text-align: center;

		.iv2-download-item__img {
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
		}
	}

	.iv2-download-item__title {
		font-size: 1.1rem;
		font-weight: bold;
		margin: 2rem 0;
	}

	.iv2-download-item__description {
		font-size: 1rem;
	}

	.iv2-download-item__btn {
		text-align: center;
	}
}
</style>