import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/components/pages/home/App.vue';

// customize
import Es from "@/components/unique/CustomizeApp.vue";

Vue.use(Router);

const router = new Router({
    mode: 'history',

    routes: [
        {
            path: '/',
            name: 'index',
            component: Home
        },
        {
            path: '/tsutayaes',
            name: 'tsutayaes',
            component: Es,
        },
        {
            path: '/starbuckses',
            name: 'starbuckses',
            component: Es,
        }
    ],

    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

export default router
