<template>
	<div class="container">
		<div class="row clearfix">
			<div class="column full">
				<div class="spacer height-60"></div>
				<div v-if="explanation">
					<p class="text-pre m-0">{{ explanation }}</p>
					<div class="spacer height-80"></div>
				</div>
			</div>
			<!-- カテゴリーリスト -->
			<div class="column full">
				<div class="iv2-news-archive px-2">
					<div class="row clearfix">
						<div class="iv2-news-category d-none d-xl-inline-block col-xl-9">
							<label
								:class="{ active: isActive === 0 }"
								class="iv2-news-category__button theme-f__news-category"
							>
								<input
									@click="clickChange(0)"
									type="radio"
									value=""
									name="category"
									class="iv2-news-category__button-input"
									autocomplete="off"
								>
								{{ publicLang.news.all_category }}
							</label>
							<label
								v-for="category in categories"
								:key="category.id"
								:class="{ active: isActive === category.id }"
								class="iv2-news-category__button theme-f__news-category"
							>
								<input
									@click="clickChange(category.id)"
									type="radio"
									:value="category.id"
									:id="'sort_' + category.id"
									name="category"
									class="iv2-news-category__button-input"
									autocomplete="off"
								>
								{{ category.name }}
							</label>
						</div>
						<div class="iv2-news-category__mobile col-12 d-flex d-xl-none align-items-center justify-content-center">
							<select
								@change="selectChange($event)"
								class="iv2-news-category__select w-100 m-3"
								name="archive"
							>
								<option value="0">{{ publicLang.news.all_category }}</option>
								<option
									v-for="category in categories"
									:key="category.id"
									:value="category.id"
								>{{ category.name }}</option>
							</select>
						</div>
						<div class="iv2-news-season col-xl-3 d-flex align-items-center justify-content-center">
							<select
								class="iv2-news-season__select m-1 d-none d-xl-block"
								name="archive"
								@change="selectArchive($event)"
							>
								<option
									value="0"
									selected
								>{{ publicLang.news.archive }}</option>
								<option
									v-for="archive in archives"
									:key="archive"
									:value="archive"
								>{{ archive + publicLang.news.year }}</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="news.length == 0"
			class="iv2-news__items-null row clearfix"
		>
			<div class="column full">
				<div style="padding: 2rem 0;">
					<h4 class="size-24 text-center font-weight-bold">{{ publicLang.news.null_title }}</h4>
					<p class="text-center">{{ publicLang.news.null_summary }}</p>
				</div>
				<div class="spacer height-60"></div>
			</div>
		</div>
		<div v-else>
			<div class="row clearfix">
				<div class="column full">
					<div class="spacer height-60"></div>
				</div>

				<div
					v-for="item in news"
					:key="item.id"
					class="iv2-news-item my-4 col-12"
				>
					<router-link
						:to="{ name: 'newsDetail', params: { id: item.id }}"
						class="iv2-news-item__link"
					>
						<div class="row">
							<div class="iv2-news-item__img fit-img col-md-4 col-12">
								<picture>
									<source
										type="image/webp"
										:srcset="item.thumbnail + publicLang.general.image_webp">
									<img
										:src="item.thumbnail"
										class="m-0"
										:alt="'「' + item.title + '」' + publicLang.news.alt"
									>
								</picture>
							</div>

							<div class="iv2-news-item__list col-md-8 col-12 px-0 px-md-5">
								<h3 class="iv2-news-item__title my-xl-4">{{ item.title }}</h3>
								<p class="iv2-news-item__summary">{{ item.content }}</p>
								<div class="d-flex">
									<p
										v-if="item.opened_at"
										class="iv2-news-item__date mr-4"
									>{{ item.opened_at }}</p>
									<p class="iv2-news-item__label mr-4">{{ item.category_name }}</p>
									<p
										v-if="!item.days_excess"
										class="iv2-news-item__new"
									>{{ themeLang.general.new_label }}</p>
								</div>
							</div>
						</div>
					</router-link>
				</div>
				<div class="column full">
					<div class="spacer height-80"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		news: Array,
		categories: Array,
		archives: Array,
		explanation: String,
		publicLang: Object,
		themeLang: Object
	},

	data() {
		return {
			isActive: 0,
		}
	},

	methods: {
		clickChange(id) {
			this.isActive = id;
			this.$emit('click-change', id);
		},

		selectChange(event) {
			this.$emit('select-change', event);
		},

		selectArchive(event) {
			this.$emit('select-archive', event);
		}
	}
}
</script>

<style lang="scss">
.iv2-news__items-null {
	padding: 8rem 0;
}

.iv2-news-item {
	.iv2-news-item__link {
		display: block;
		text-decoration: none;

		&:hover {
			.iv2-news-item__img {
				opacity: 0.7;
			}
		}

		.iv2-news-item__img {
			height: 250px;
			border: 1px solid #707070;
			-webkit-transition: 0.3s;
			transition: 0.3s;
		}

		.iv2-news-item__date {
			line-height: 1;
			margin-right: 20px;
		}

		.iv2-news-item__new {
			color: #a83535;
		}

		.iv2-news-item__label,
		.iv2-news-item__new {
			line-height: 1;
			position: relative;

			&::before {
				content: '';
				height: 20px;
				width: 1px;
				background-color: #707070;
				position: absolute;
				top: 50%;
				left: -12px;
				transform: translateY(-50%);
			}
		}

		.iv2-news-item__date,
		.iv2-news-item__label,
		.iv2-news-item__new {
			font-size: 14px;
		}

		.iv2-news-item__title {
			font-size: 1.7rem;
			font-weight: bold;
		}
	}
}

/* PC */
@media (min-width: 1200px) {
	.iv2-news-archive {
		.iv2-news-season__select {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			padding-left: 10px;
			border-radius: 0;
			border: 1px solid #000000;
			width: 180px;
			background-color: #ffffff;

			&::-ms-expand {
				display: none;
			}
		}

		.iv2-news-season:before {
			content: "";
			top: 50%;
			right: 70px;
			display: block;
			position: absolute;
			z-index: 3;
			width: 7px;
			height: 7px;
			border-top: 2px solid #000;
			border-right: 2px solid #000;
			-webkit-transform: translateY(-50%) rotate(135deg);
			transform: translateY(-50%) rotate(135deg);
		}
		.iv2-news-category__button {
			display: inline-block;
			background-color: transparent;
			border: none;
			margin: 0;
			padding: 7px 15px 0;
			border-bottom: 3px solid transparent;
			-webkit-transition: 0.2s;
			transition: 0.2s;
			cursor: pointer;
			position: relative;

			.iv2-news-category__button-input {
				display: none;
				:checked {
					.iv2-news-category__button {
						border-bottom: 3px solid;
					}
				}
			}
			.iv2-news-category__button-name {
				margin: auto;
			}
		}
	}
	.iv2-news-item__list {
		padding: 0 100px;
	}
}

/* TABLET or SP */
@media (max-width: 1199px) {
	.iv2-news-archive {
		padding: 10px;
		background-color: transparent !important;

		.iv2-news-category__select {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			border-radius: 0;
			border: 1px solid #000;
			padding: 0 10px;
			background-color: #fff;
		}

		.iv2-news-category__mobile:before {
			content: "";
			right: 45px;
			display: block;
			position: absolute;
			z-index: 3;
			width: 7px;
			height: 7px;
			border-top: 2px solid #000;
			border-right: 2px solid #000;
			-webkit-transform: rotate(135deg);
			transform: rotate(135deg);
		}
	}
}

/* TABLET Only */
@media (max-width: 1199px) and (min-width: 768px) {
	.iv2-news-item {
		.iv2-news-item__link {
			.iv2-news-item__title {
				font-size: 1.5rem;
			}
		}
	}
}

/* SP Only */
@media (max-width: 767px) {
	.iv2-news-item {
		.iv2-news-item__link {
			.iv2-news-item__title {
				font-size: 1.2rem;
			}

			.iv2-news-item__summary {
				font-size: 1rem;
				margin: 0;
				line-height: 1.5;
			}
		}
	}
}
</style>