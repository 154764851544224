<template>
	<footer class="iv2-footer theme-f__footer-bg">
		<div class="iv2-footer__container theme-f__footer-border theme-f__footer-text">
			<div class="row clearfix px-md-5 px-2">
				<div class="iv2-footer__item col-xl-6">
					<div class="row clearfix">
						<div class="iv2-footer__logo col-md-5 text-center">
							<a :href="'/' + lang + query">
								<picture>
									<source
										type="image/webp"
										:srcset="information.logo_image_footer + publicLang.general.image_webp"
									>
									<img
										:src="information.logo_image_footer"
										class="iv2-footer__logo-img"
										:alt="organization.name + publicLang.general.alt"
									>
								</picture>
							</a>
						</div>
						<div class="col-md-7">
							<p
								v-if="organization.name"
								class="mt-0 mb-2 size-14"
							>{{ organization.name }}</p>
							<p
								v-if="organization.zip_code"
								class="m-0 size-14"
								style="line-height: 1.2;"
							>{{ "〒" + organization.zip_code }}</p>
							<div style="line-height: 1.2;">
								<span
									v-if="organization.prefecture_name"
									class="size-14 m-0"
								>{{ organization.prefecture_name }}</span>
								<span
									v-if="organization.city_name"
									class="size-14 m-0"
								>{{ organization.city_name }}</span>
								<span
									v-if="organization.town_name"
									class="size-14 m-0"
								>{{ organization.town_name }}</span>
								<span
									v-if="organization.building_name"
									class="size-14 m-0 pl-1"
								>{{ organization.building_name }}</span>
							</div>
							<div class="d-flex my-1">
								<p
									v-if="organization.tel_number"
									class="size-14 m-0 mr-2"
								>
									<span class="mr-1">{{ themeLang.general.tel }}</span>
									<span>{{ organization.tel_number }}</span>
								</p>
								<p
									v-if="organization.fax_number"
									class="size-14 m-0"
								>
									<span class="mr-1">{{ themeLang.general.fax }}</span>
									<span>{{ organization.fax_number }}</span>
								</p>
							</div>
						</div>
						<div class="col-md-11 mt-md-3">
							<p class="size-14">{{ information.meta_description }}</p>
						</div>
					</div>
				</div>
				<div class="iv2-footer__nav col-xl-6 text-left">
					<ul class="iv2-footer__nav-list">
						<li
							v-for="nav in footer"
							:key="nav.id"
							class="iv2-footer__nav-item"
						>
							<router-link
								v-if="nav.link_type == 'internal'"
								:to="nav.link_href + lang + query"
								:target="nav.target_type"
								class="iv2-footer__nav-link theme-f__footer-link"
							>{{ nav.item_name }}</router-link>
							<a
								v-else-if="nav.link_type == 'external'"
								:href="nav.link_href"
								:target="nav.target_type"
								rel="noopener"
								class="iv2-footer__nav-link theme-f__footer-link"
							>{{ nav.item_name }}</a>
							<ul
								v-if="!nav.children.length == false"
								class="iv2-footer__nav-list list-unstyled"
							>
								<li
									v-for="cNav in nav.children"
									:key="cNav.id"
									class="iv2-footer__nav-item"
								>
									<router-link
										v-if="cNav.link_type == 'internal'"
										:to="cNav.link_href + lang + query"
										:target="cNav.target_type"
										class="iv2-footer__nav-link theme-f__footer-link"
									>{{ cNav.item_name }}</router-link>
									<a v-else-if="cNav.link_type == 'external'"
										:href="cNav.link_href"
										:target="cNav.target_type"
										rel="noopener"
										class="iv2-footer__nav-link theme-f__footer-link"
									>{{ cNav.item_name }}</a>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
			<div class="iv2-footer__bottom row">
				<div
					:class="{translation: translation.is_use == 1}"
					class="iv2-footer__bottom-item col-xl-6"
				>
					<div
						v-if="translation.is_use == 1"
						class="iv2-language"
					>
						<button
							class="iv2-language-btn theme-f__footer-text d-none d-xl-block"
							@mouseover="langSelector.active = true"
						><i class="fas fa-globe theme-f__footer-text size-12 mr-2"></i>{{ langSelector.select }}</button>
						<ul
							@mouseleave="langSelector.active = false"
							:class="{active: langSelector.active}"
							class="iv2-language__selector theme-f__footer-bg list-unstyled d-none d-xl-block"
						> 
							<li
								v-for="item in translation.active_language"
								:key="item.code"
								@click="changeTranslation(item.code)"
								:class="{opacity: item.name == langSelector.select}"
								class="iv2-language__selector-list theme-f__footer-text"
							>{{ item.name }}</li>
						</ul>
						<button
							class="iv2-language-btn theme-f__footer-text d-block d-xl-none"
							@click="activeSelect"
						><i class="fas fa-globe theme-f__footer-text size-12 mr-2"></i>{{ langSelector.select }}</button>
						<ul
							:class="{active: langSelector.active}"
							class="iv2-language__selector theme-f__footer-bg list-unstyled d-block d-xl-none"
						> 
							<li
								v-for="item in translation.active_language"
								:key="item.code"
								@click="changeTranslation(item.code)"
								:class="{opacity: item.name == langSelector.select}"
								class="iv2-language__selector-list theme-f__footer-text"
							>{{ item.name }}</li>
						</ul>
					</div>
				</div>
				<div class="col-6 d-none d-xl-block text-right">
					<small
						v-if="showDisplay"
						@click="scrollTop"
						class="iv2-footer__pagetop"
					>{{ themeLang.general.page_top }}<i class="fas fa-angle-double-up ml-2"></i></small>
				</div>
			</div>
			<hr class="iv2-footer__hr theme-f__footer-hr my-1">
		</div>

		<!-- フリーページ項目に対応した動的height値指定 -->
		<div is="style">
			@media (min-width: 768px) {
				{{ setHeight() }}
			}
		</div>
		<!-- フリーページ項目に対応した動的height値指定 -->
	</footer>
</template>
<script>
export default {
	props: {
		footer: Array,
		organization: Object,
		information: Object,
		translation: Object,
		publicLang: Object,
		themeLang: Object,
		count: Number
	},

	data() {
		return {
			query: '',
			lang: '',
			langSelector: {
				active: false,
				select: ''
			},
			showDisplay: false
		}
	},

	created() {
		/** language初期値のセットアップ */
		var query = this.translation.active_language.filter(e => e.code == this.$route.query.hl);
		var cookie = this.translation.active_language.filter(e => e.code == this.$cookies.get('iv-language'));
		var type = this.translation.active_language.filter(e => e.code == this.translation.default_lang);
		if (query.length > 0) {
			this.langSelector.select = query[0].name;
		} else if (cookie.length > 0) {
			this.langSelector.select = cookie[0].name;
		} else {
			this.langSelector.select = type[0].name;
		}

		if (this.$route.path == '/') {
			this.showDisplay = false;
		} else {
			this.showDisplay = true;
		}
	},

	mounted() {
		if (this.$route.query.mode == 'preview') {
			this.query = '?mode=preview';
		}
		if (this.$route.query.hl) {
			this.lang = '?hl=' + this.$route.query.hl;
		}
	},

	methods: {
		scrollTop() {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		},

		activeSelect() {
			this.langSelector.active = !this.langSelector.active;
		},

		/** language変更時のcookie保存処理 */
		changeTranslation(val) {
			this.langSelector.active = false;
			// cookie保存
			this.$cookies.set('iv-language', val, '30min');

			// ページの再レンダリング
			var route = this.$route.path;
			this.$router.push({path: route});
			this.$router.go({path: this.$router.currentRoute.path, force: true});
		},

		setHeight() {
			let navHeight = this.count * 15;
			if (navHeight > 260) {
				return '.iv2-footer .iv2-footer__container .iv2-footer__nav-list {max-height: ' + navHeight + 'px;}';
			}
		}
	}
}
</script>

<style lang="scss">
.iv2-footer {
	padding: 0 80px 35px;

	.iv2-footer__container {
		margin: auto;
		padding: 50px 0 5px;

		.iv2-footer__logo {
			.iv2-footer__logo-img {
				max-width: 80%;
				max-height: 150px;
			}
		}

		.iv2-footer__item {
			text-align: left;
		}

		.iv2-footer__nav-list {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			max-height: 260px;
			margin-bottom: 30px;
			padding-left: 0px;
			margin-right: 5px;

			.iv2-footer__nav-item {
				width: 33.3%;
				font-size: 14px;
				display: block;
				padding-right: 20px;

				.iv2-footer__nav-link {
					padding: 0;
					text-decoration: none;
				}

				> .iv2-footer__nav-list {
					display: block;
					margin-bottom: 0;
					margin-right: 0px;
					padding-top: 0;
					padding-left: 0;
					border-left: 0;

					.iv2-footer__nav-item {
						width: 100%;
						padding-right: 0;
						padding-bottom: 0;

						.iv2-footer__nav-link {
							margin-left: 10px;

							&::before {
								content: "・";
							}
						}
					}
				}
			}
		}

		.iv2-footer__hr {
			padding: 0;
		}

		.iv2-footer__bottom {
			.iv2-footer__bottom-item {
				.iv2-language {
					position: relative;

					.iv2-language-btn {
						-webkit-appearance: none;
						-moz-appearance: none;
						appearance: none;
						border: 0;
						border-radius: 0;
						background: transparent;
						font-size: 13px;
					}

					.iv2-language__selector {
						padding: 5px 0px;
						position: absolute;
						top: 0;
						transform: translateY(-110%);
						width: 130px;
						-webkit-appearance: none;
						appearance:none;
						border: 1px solid;
						border-radius: 5px;
						transition: .3s;
						opacity: 0;
						visibility: hidden;

						&.active {
							opacity: 1;
							visibility: inherit;
						}

						.iv2-language__selector-list {
							padding: 3px 13px;
							cursor: pointer;

							&.opacity {
								opacity: 0.6;
							}
						}
					}
				}
			}
			
			.iv2-footer__pagetop {
				cursor: pointer;
			}
		}
	}
}

/* TABLET or SP */
@media (max-width: 1199px) {
	.iv2-footer {
		padding: 0 20px 35px;
	}

	.iv2-footer__container {
		.iv2-footer__bottom {
			.iv2-footer__bottom-item {
				justify-content: center;

				&.translation {
					justify-content: space-between;
				}
			}
		}

		.iv2-footer__hr {
			margin: 10px 0;
		}
	}
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
	.iv2-footer {
		padding: 0 50px 35px;
	}
}

/* SP Only */
@media (max-width: 767px) {
	.iv2-footer {
		margin-bottom: 60px;
		padding-bottom: calc(20px + env(safe-area-inset-bottom));

		.iv2-footer__container {
			padding: 20px 0 5px;

			.iv2-footer__logo {
				margin: 25px auto;
			}

			.iv2-footer__nav {
				display: none;
			}
		}
	}
}
</style>