<template>
	<transition>
		<div
			class="iv2__view-more-btn size-14 position-fixed"
			@click="slideLayout"
			:class="{ 'first-view': viewMore == 'pattern' }"
		>
			<div class="iv2__view-more-arrow">
				<span class="iv2__first-arrow"></span>
				<span class="iv2__second-arrow"></span>
			</div>
			<p
				v-if="viewMore == 'pattern'"
				class="m-0"
			>{{ themeLang.general.more }}</p>
		</div>
	</transition>
</template>

<script>
export default {
	props: {
		viewMore: String,
		themeLang: Object
	},

	data() {
		return {
			changeLayout: '',
		}
	},

	methods: {
		slideLayout() {
			this.changeLayout = this.viewMore;
			this.$emit('change-layout', this.changeLayout);
		}
	}
}
</script>

<style lang="scss">
.iv2__view-more-btn {
	cursor: pointer;
	display: block;
	bottom: 30px;
	left: 50%;
	transform: translateX(-50%);
	color: #fff !important;
	text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
	z-index: 100;
	visibility: hidden;
	opacity: 0;
	transition: .3s;

	.iv2__view-more-arrow {
		content: "";
		height: 35px;
		width: 35px;
		background-color: rgba(250, 250, 250, 0.8);
		border-radius: 50px;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: -35px;
		opacity: 0.7;
		filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));

		.iv2__first-arrow {
			&::before {
				content: "";
				border-left: 1px solid #3C3C3C;
				width: 1px;
				height: 11px;
				position: absolute;
				top: 33%;
				left: 12.5px;
				transform: rotate(-60deg);
			}

			&::after {
				content: "";
				border-left: 1px solid #3C3C3C;
				width: 1px;
				height: 11px;
				position: absolute;
				top: 33%;
				right: 12.5px;
				transform: rotate(60deg);
			}
		}

		.iv2__second-arrow {
			&::before {
				content: "";
				border-left: 1px solid #3C3C3C;
				width: 1px;
				height: 11px;
				position: absolute;
				top: 45%;
				left: 12.5px;
				transform: rotate(-60deg);
			}

			&::after {
				content: "";
				border-left: 1px solid #3C3C3C;
				width: 1px;
				height: 11px;
				position: absolute;
				top: 45%;
				right: 12.5px;
				transform: rotate(60deg);
			}
		}
	}

	&.first-view {
		bottom: 55px;

		&::after {
			content: "";
			height: 20px;
			width: 1px;
			border-left: 1px solid #fff;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: -20px;
		}
	}

	&.scroll {
		visibility: visible;
		opacity: 1;
	}
}

/* SP Only */
@media (max-width: 767px) {
	.iv2__view-more-btn {
		bottom: 0px;
		padding-bottom: 80px;
		padding-bottom: calc(80px + env(safe-area-inset-bottom));

		&.first-view {
			bottom: 0px;
			
			&::after {
				content: none;
			}
		}
	}
}
</style>