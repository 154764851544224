<template>
	<div>
		<article
			class="iv2-article"
			v-if="pageActive"
		>
			<div v-if="coupon.open_type === 1 && indexOpenType === 1">
				<BaseTitle
					:items="items"
					:public-lang="publicLang"
				/>
				<LayoutPattern :coupon="coupon" />
				<LayoutModal
					:coupon="coupon"
					:public-lang="publicLang"
				/>
			</div>

			<Error404
				v-else-if="coupon.open_type === 0 || indexOpenType === 0 || pageDisplay === 0"
				:public-lang="publicLang"
			/>
		</article>
		<div v-if="loading">
			<BaseLoading />
		</div>
	</div>
</template>

<script>
import BaseTitle from '@/components/items/BaseTitle.vue';
import LayoutPattern from './components/LayoutPattern.vue';
import LayoutModal from './components/LayoutModal.vue';
import BaseLoading from '@/components/items/BaseLoading.vue';
import Error404 from '@/components/systems/Page404.vue';
import Meta from '@/mixins/MetaSettable';
import Preview from '@/mixins/Preview';

export default {
	components: {
		BaseTitle,
		LayoutPattern,
		LayoutModal,
		BaseLoading,
		Error404
	},

	mixins: [Meta, Preview],

	props: {
		language: String,
		publicLang: Object
	},

	data() {
		return {
			coupon: [],
			items: {
				title: '',
				subtitle: '',
				header_type: 'text_only'
			},
			indexOpenType: '',
			pageDisplay: '',
			pageActive: false,
			loading: true
		}
	},

	mounted() {
		this.axios.get('/coupons', {
			params: {
				hl: this.language
			}
		}).then(({data}) => {
			this.items.title = data.response.title;
			this.items.subtitle = data.response.subtitle;
			if (this.$route.query.mode == 'preview') {
				this.indexOpenType = 1;
			} else {
				this.indexOpenType = data.response.open_type;
			}
		}).catch(() => {
			this.indexOpenType = 0;
		});

		const id = this.$route.params.id;
		if (this.$route.query.mode == 'preview' && this.$route.query.api_token) {
			this.axios.get('/preview/coupons/' + id + '/show', {
				headers: {
					Authorization: `Bearer ${this.$cookies.get('preview-token')}`,
				},
				params: {
					hl: this.language,
				}
			}).then((response) => {
				this.coupon = response.data.response;
			}).catch(() => {
				this.pageDisplay = 0;
			}).finally(() => {
				this.loading = false;
				this.pageActive = true;
			});
		} else {
			this.axios.get('/coupons/' + id + '/show', {
				params: {
					hl: this.language,
				}
			}).then((response) => {
				this.coupon = response.data.response;
				if (this.$route.query.mode == 'preview') {
					this.coupon.open_type = 1;
				}
			}).catch(() => {
				this.pageDisplay = 0;
			}).finally(() => {
				this.loading = false;
				this.pageActive = true;
			});
		}
	}
}
</script>

<style lang="scss"></style>