<template>
	<transition
		name="fade"
		appear
	>
		<div class="iv2-loading">
			<loading
				:active.sync="isLoading"
				loader="dots"
			></loading>
		</div>
	</transition>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	components: {
		Loading
	},

	data() {
		return {
			isLoading: true
		}
	}
}
</script>

<style lang="scss">
.iv2-loading {
	height: 100vh;
}
</style>