import { render, staticRenderFns } from "./CustomizeExpiredAfter.vue?vue&type=template&id=aee507a8&"
import script from "./CustomizeExpiredAfter.vue?vue&type=script&lang=js&"
export * from "./CustomizeExpiredAfter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports