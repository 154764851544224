<template>
    <div>
        <h1 class="h4 my-5 text-center iv-headline">{{ moduleSettings.headline }}</h1>
        <hr>
    </div>
</template>

<script>
export default {
	props: {
		moduleSettings: Object
	}
}
</script>

<style lang="scss">
    .iv2-article .iv-headline {
        font-weight: 500;
    }
</style>