<template>
	<div
		class="iv2-pattern__container container-fluid"
		:class="{ 'py-0': responsive === true }"
		v-html="items.content"
	></div>
</template>

<script>
export default {
	props: {
		items: Object,
		news: Object,
		blog: Object,
		showDisplay: String,
		responsive: Boolean
	},

	data() {
		return {
			viewReturn: 'hero',
			viewMore: '',
			height: ''
		}
	},

	mounted() {
		if (this.responsive === false) {
			this.changeLayout();
		}
	},

	updated() {
		if (this.showDisplay == 'pattern' && this.responsive === false) {
			this.changeLayout();
		}
	},

	methods: {
		// 前後のセクションを定義
		changeLayout() {
			if ( this.news.data.length > 0 && this.items.display_news_number > 0 ) {
				this.viewMore = 'news';
			} else if ( this.blog.is_linked_blog == 1 && this.blog.articles.length > 0 ) {
				this.viewMore = 'rss';
			}
			this.height = document.getElementsByClassName('iv2-pattern__container')[0].clientHeight;
			this.$emit('view-more', this.viewMore, this.height);
			this.$emit('view-return', this.viewReturn);
		}
	}
}
</script>

<style lang="scss">
.iv2-pattern__container {
	padding-top: 80px;
	padding-bottom: 70px;
}

/* SP Only */
@media (max-width: 767px) {
	.iv2-pattern__container {
		padding-top: 60px;
		padding-bottom: 100px;
	}
}
</style>
