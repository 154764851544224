<template>
    <div>
        <BaseHeadline :moduleSettings="moduleSettings" />
        <div class="text-left">
            <p class="iv-paragraph">アンケートにご協力いただきありがとうございます。</p>
            <p class="iv-paragraph">アンケートは {{ start }} より実施いたします。<br>申し訳ございませんが、今しばらくお待ちください。</p>
            <p class="iv-paragraph">● アンケート実施期間<br>{{ start }} 〜 {{ end }}</p>
        </div>
    </div>
</template>

<script>
import BaseHeadline from '../items/BaseHeadline.vue';

export default {
    components: {
        BaseHeadline
    },

    props: {
        forms: Object,
        start: String,
        end: String,
        num: Number,
        time: Number,
        moduleSettings: Object
    }
};
</script>

<style lang="scss"></style>
