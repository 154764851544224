<template>
	<div class="container">
		<div class="row clearfix">
			<div
				v-if="research.form_name"
				class="column full"
			>
				<h3 class="iv2-research-form__title">{{ research.form_name }}</h3>
			</div>
			<div
				v-if="research.form_description"
				class="column full"
			>
				<p class="iv2-research-form__sumally text-pre">{{ research.form_description }}</p>
			</div>

			<form @submit.prevent="submit">
				<loading
					:active.sync="isLoading"
					:is-full-page="fullPage"
					:background-color="bgColor"
				></loading>
				<div
					v-if="success"
					class="iv2-research-form__response-text text-pre"
				>{{ publicLang.research.send_text }}</div>
				<div
					v-else-if="timeout"
					class="iv2-research-form__response-text text-pre"
				>{{ publicLang.research.send_error }}</div>
				<div
					v-else-if="invalid"
					class="m-4 text-danger text-pre"
				>{{ publicLang.research.send_invalid }}</div>
				<div class="column full">
					<div class="spacer height-40"></div>
				</div>

				<div class="column full">
					<label class="d-block mt-3">
						<span class="badge badge-danger mr-2">{{ publicLang.general.required }}</span>
						<span>{{ publicLang.research.message }}</span>
					</label>
					<textarea
						class="iv2-research-form__item w-100 p-2"
						id="message"
						name="request_text"
						rows="5"
						:placeholder="publicLang.research.msg_placeholder"
						v-model="fields.request_text"
					></textarea>
					<div
						v-if="errors && errors.request_text"
						class="text-danger"
					>{{ errors.request_text[0] }}</div>
				</div>

				<div class="column full">
					<label class="d-block mt-3">
						<span class="badge badge-danger mr-2">{{ publicLang.general.required }}</span>
						<span>{{ publicLang.research.recommend_title }}</span>
					</label>
				</div>

				<div class="column full">
					<div class="rating position-relative mt-4">
						<div class="rating__num d-flex justify-content-between">
							<p class="m-0">0</p>
							<p class="m-0">10</p>
						</div>
						<div class="rating__bar mt-2 mb-3">
							<label class="w-100">
								<span
									class="rating__active-num size-24 position-absolute text-center"
									:style="'margin-left:' + ((fields.recommend_level * 10) - ((fields.recommend_level - 5) * 0.2)) + '%;'"
								>{{fields.recommend_level}}</span>
								<input
									class="rating__active-bar w-100"
									type="range"
									min="0"
									max="10"
									name="recommend_level"
									v-model.number="fields.recommend_level"
								>
							</label>
							<div
								v-if="errors && errors.recommend_level"
								class="text-danger"
							>{{ errors.recommend_level[0] }}</div>
						</div>

						<div class="rating-text d-flex justify-content-between">
							<p class="m-0">{{ publicLang.research.no_think }}</p>
							<p class="m-0">{{ publicLang.research.think }}</p>
						</div>
					</div>
				</div>

				<div class="column full">
					<div class="spacer height-40"></div>
					<hr>
					<div class="spacer height-40"></div>
				</div>
				<div class="column full">
					<p>{{ publicLang.research.answer_title }}</p>
					<label class="d-block mt-3">
						<span class="badge badge-info mr-2">{{ publicLang.general.any }}</span>
						<span>{{ publicLang.research.gender.text }}</span>
					</label>
					<label
						v-for="gender in publicLang.research.gender.list"
						:key="gender.id"
						class="mb-0 mr-1"
					>
						<input
							type="radio"
							class="iv2-research-form__item m-2"
							name="gender_type"
							:value="gender.id"
							v-model="fields.gender_type"
						>{{gender.name}}
					</label>
					<div
						v-if="errors && errors.gender_type"
						class="text-danger"
					>{{ errors.gender_type[0] }}</div>
				</div>
				<div class="column full">
					<label class="d-block mt-3">
						<span class="badge badge-info mr-2">{{ publicLang.general.any }}</span>
						<span>{{ publicLang.research.age.text }}</span>
					</label>
					<label
						v-for="age in publicLang.research.age.list"
						:key="age.id"
						class="mb-0 mr-1"
					>
						<input
							type="radio"
							class="iv2-research-form__item m-2"
							name="years_old"
							:value="age.id"
							:id="'age' + age.id"
							v-model="fields.years_old"
						>
						{{age.name}}
					</label>
					<div
						v-if="errors && errors.years_old"
						class="text-danger">{{ errors.years_old[0] }}
					</div>
				</div>
				<div class="column full">
					<label class="d-block mt-3">
						<span class="badge badge-info mr-2">{{ publicLang.general.any }}</span>
						<span>{{ publicLang.research.occupation.text }}</span>
					</label>
					<label
						v-for="jobs in publicLang.research.occupation.list"
						:key="jobs.id"
						class="mb-0 mr-1"
					>
						<input
							type="radio"
							class="iv2-research-form__item m-2"
							name="jobs"
							:value="jobs.id"
							:id="'jobs' + jobs.id"
							v-model="fields.jobs"
						>{{jobs.name}}
					</label>
					<div
						v-if="errors && errors.jobs"
						class="text-danger"
					>{{ errors.jobs[0] }}</div>
				</div>
				<div class="column full">
					<label class="d-block mt-3">
						<span class="badge badge-info mr-2">{{ publicLang.general.any }}</span>
						<span>{{ publicLang.research.name }}</span>
					</label>
					<input
						type="text"
						class="iv2-research-form__item w-75 p-2"
						name="name"
						id="name"
						v-model="fields.name"
						:placeholder="publicLang.research.name"
					>
					<div
						v-if="errors && errors.name"
						class="text-danger"
					>{{ errors.name[0] }}</div>
				</div>
				<div class="column full">
					<label class="d-block mt-3">
						<span class="badge badge-info mr-2">{{ publicLang.general.any }}</span>
						<span>{{ publicLang.research.email }}</span>
					</label>
					<input
						type="email"
						class="iv2-research-form__item w-75 p-2"
						name="email"
						id="email"
						v-model="fields.email"
						:placeholder="publicLang.research.email"
					>
					<div
						v-if="errors && errors.email"
						class="text-danger"
					>{{ errors.email[0] }}</div>
				</div>
				<div class="column full">
					<label class="d-block mt-3">
						<span class="badge badge-info mr-2">{{ publicLang.general.any }}</span>
						<span>{{ publicLang.research.tel }}</span>
					</label>
					<input
						type="tel"
						class="iv2-research-form__item w-75 p-2"
						name="tel_number"
						id="tel_number"
						v-model="fields.tel_number"
						:placeholder="publicLang.research.tel"
					>
					<div
						v-if="errors && errors.tel_number"
						class="text-danger"
					>{{ errors.tel_number[0] }}</div>
				</div>
				<div class="column full mb-5">
					<p class="mb-4 text-pre">{{ publicLang.research.can_contact.text }}</p>
					<label
						v-for="can_contact in publicLang.research.can_contact.list"
						:key="can_contact.id"
						class="mb-0 mr-1"
					>
						<input
							type="radio"
							class="iv2-research-form__item m-2"
							name="can_contact"
							:value="can_contact.id"
							v-model="fields.can_contact"
						>{{can_contact.name}}
					</label>
					<div
						v-if="errors && errors.can_contact"
						class="text-danger"
					>{{ errors.can_contact[0] }}</div>
				</div>
				<div class="column full">
					<div v-if="policy === 1">
						<p class="text-center mb-0">
							<router-link
								to="/privacypolicy"
								target="_blank"
								class="iv2-research__privacy-link"
							>{{ publicLang.research.privacy_link }}</router-link>{{ publicLang.research.privacy_text }}
						</p>
						<div class="text-center">
							<label style="cursor: pointer;">
								<input
									type="checkbox"
									name="is_agreement"
									id="is_agreement"
									v-model="fields.is_agreement"
								>{{ publicLang.research.agreement }}
								<div
									v-if="errors && errors.is_agreement"
									class="text-danger"
								>{{ errors.is_agreement[0] }}</div>
							</label>
						</div>
					</div>
					<p class="text-center mt-5">
						<input
							class="iv2-research-form__btn theme__main-btn"
							type="submit"
							:value="publicLang.research.button"
							:disabled="!fields.is_agreement"
						>
					</p>
					<div class="spacer height-100"></div>
				</div>
				<div v-if="policy === 0">
					<input
						type="hidden"
						v-model="fields.is_agreement"
					>
				</div>
				<input
					type="hidden"
					v-model="fields.user_agent"
				>
			</form>
		</div>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	components: {
		Loading,
	},

	props: {
		research: Object,
		language: String,
		publicLang: Object
	},

	data() {
		return {
			fields: {
				request_text: '',
				recommend_level: 5,
				gender_type: 0,
				years_old: 0,
				jobs: 0,
				name: '',
				email: '',
				tel_number: '',
				is_agreement: '',
				user_agent: ''
			},
			errors: {},
			policy: '',
			numNone: false,
			success: false,
			timeout: false,
			invalid: false,
			fullPage: true,
			isLoading: false,
			bgColor: '#525252'
		};
	},

	mounted() {
		this.axios.get(process.env.VUE_APP_CONTACT_POLICY, {
			params: {
				hl: this.language,
			}
		}).then((response) => {
			this.policy = response.data.response.open_type;
			if (this.policy == 0) {
				this.fields.is_agreement = true;
			} else {
				this.fields.is_agreement = '';
			}
		})
	},

	methods: {
		submit() {
			this.isLoading = true;
			this.fields.user_agent = window.navigator.userAgent;
			this.success = false;
			this.timeout = false;
			this.invalid = false;
			this.errors = {};

			this.axios
				.post('/enquetes/' + process.env.VUE_APP_ENQUETE,
					this.fields,
					{
						withCredentials: true,
						timeout : 30000
					}
				).then(() => {
					this.fields = {
						request_text: '',
						recommend_level: 5,
						gender_type: 0,
						years_old: 0,
						jobs: 0,
						name: '',
						email: '',
						tel_number: '',
						user_agent: ''
					}; //Clear input fields.
					if (this.policy == 0) {
						this.fields.is_agreement = true;
					} else {
						this.fields.is_agreement = '';
					}
					this.fields.recommend_level = 5;
					this.success = true;
					// console.log(response);
				}).catch((error) => {
					const isTimeout = error.code === 'ECONNABORTED';
					if (isTimeout || !error.response) {
						this.timeout = true;
					}
					if (error.response.status === 422) {
						this.errors = error.response.data.errors || {};
						this.invalid = true;
					} else {
						this.timeout = true;
					}
				}).finally(() => {
					this.isLoading = false;
					window.scrollTo({
						top: 0,
						behavior: 'smooth'
					});
				});
		}
	}
}
</script>

<style lang="scss">
.rating__bar {
	.rating__active-bar {
		-webkit-appearance: none;
		appearance: none;
		outline: none;
		border: 0;

		/* Chrome */
		&::-webkit-slider-thumb {
			-webkit-appearance: none;
			appearance: none;
			cursor: pointer;
			width: 20px;
			height: 20px;
			margin-top: -7px;
			display: block;
			background-color: #fd7776;
			border-radius: 50%;
			-webkit-border-radius: 50%;
			transition: 0.3s;
		}

		&:hover::-webkit-slider-thumb {
			background-color: #fff;
			box-shadow: 0 0 0 4px #fd7776;
		}

		&::-webkit-slider-runnable-track {
			height: 6px;
			-webkit-appearance: none;
			background-color: #aaa;
			border-radius: 25px;
		}

		/* Firefox */
		&::-moz-range-thumb {
			appearance: none;
			cursor: pointer;
			width: 16px;
			height: 16px;
			margin-top: -5px;
			display: block;
			background-color: #fd7776;
			border-radius: 50%;
			border-color: #fd7776;
			transition: 0.3s;
		}

		&:hover::-moz-range-thumb {
			background-color: #fff;
			border-color: #fff;
			box-shadow: 0 0 0 4px #fd7776;
		}

		&::-moz-range-track {
			height: 6px;
			background-color: #aaa;
			border-radius: 25px;
		}
	}

	.rating__active-num {
		width: 30px;
		background-color: #fff;
		transform: translateX(-50%);
		top: -15px;
		display: block;
		box-sizing: border-box;
	}
}

.iv2-research-form__sumally {
	letter-spacing: 3px;
}

.iv2-research-form__response-text {
	background-color: #ddd;
	color: #000;
	margin: 1rem 0;
	padding: 0.75rem 1.25rem;
	border-radius: 5px;
}

.iv2-research-form__item {
	border: 1px solid #8c8c8c;
}

.iv2-research-form__btn {
	text-align: center;
	display: inline-block;
	border: 0;
	font-size: 18px;
	letter-spacing: 2px;
	padding-top: 15px;
	padding-bottom: 15px;
	border-radius: 0;
	width: 300px;
	max-width: 100%;
}

input[type="submit"][disabled] {
  opacity: .2;

	&:hover {
		transform: scale(1);
		opacity: .2;
	}
}
</style>