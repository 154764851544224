<template>
	<div>
		<article
			class="iv2-article"
			v-if="pageActive"
		>
			<div v-if="indexOpenType === 1">
				<BaseTitle
					:items="items"
					:public-lang="publicLang"
				/>

				<!-- ダウンロード一覧 -->
				<LayoutList
					:download="download"
					:explanation="explanation"
					:public-lang="publicLang"
				/>

				<!-- ページネーション -->
				<BasePagination
					v-if="download.length > 0"
					:last-page="lastPage"
					@pagination="pagination"
				/>
			</div>

			<Error404
				v-else-if="indexOpenType === 0"
				:public-lang="publicLang"
			/>
		</article>

		<div v-if="loading">
			<BaseLoading />
		</div>
	</div>
</template>

<script>
import BaseTitle from '@/components/items/BaseTitle.vue';
import LayoutList from './components/LayoutList.vue';
import BaseLoading from '@/components/items/BaseLoading.vue';
import BasePagination from '@/components/items/BasePagination.vue';
import Error404 from '@/components/systems/Page404.vue';
import Meta from '@/mixins/MetaSettable';
import Preview from '@/mixins/Preview';

export default {
	components: {
		BaseTitle,
		LayoutList,
		BaseLoading,
		BasePagination,
		Error404,
	},

	mixins: [Meta, Preview],

	props: {
		language: String,
		publicLang: Object
	},

	data() {
		return {
			download: [],
			items: {
				title: '',
				subtitle: '',
				header_type: 'text_only'
			},
			lastPage: 1,
			sortPage: '',
			explanation: '',
			indexOpenType: '',
			pageActive: false,
			loading: true
		}
	},

	mounted() {
		this.load();
	},

	methods: {
		load() {
			this.axios.get('/downloads', {
				params: {
					hl: this.language,
					page: this.sortPage,
				}
			}).then(({data}) => {
				this.download = data.response.data;
				this.items.title = data.response.title;
				this.items.subtitle = data.response.subtitle;
				this.lastPage = data.response.last_page;
				this.explanation = data.response.explanation;
				if (this.$route.query.mode == 'preview') {
					this.indexOpenType = 1;
				} else {
					this.indexOpenType = data.response.open_type;
				}
			}).catch(() => {
				this.indexOpenType = 0;
			}).finally(() => {
				this.loading = false;
				this.pageActive = true;
				window.scrollTo({
					top: 0,
				});
			});
		},

		pagination (page) {
			this.loading = true;
			if (page >= 1 && page <= this.lastPage) {
				this.sortPage = page;
				this.load();
			}
		}
	}
}
</script>

<style lang="scss"></style>