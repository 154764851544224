<template>
	<div id="app">
		<div v-if="openType === 1 && statusCode === 200">
			<TheGlobal
				:class="{scroll: scrollY > 50 || showDisplay}"
				:global="global"
				:extra="extra"
				:sns="sns"
				:organization="organization"
				:information="information"
				:rss="rss"
				:public-lang="publicLang"
				:count="count.global"
			/>
			<BaseDrawer />
			<div class="iv2-sidebar theme-f__extra-sns-bg d-none d-xl-flex">
				<div
					:class="{scroll: scrollY > 50 || showDisplay}"
					class="iv2-sidebar__bg theme-f__sidebar-bg">
				</div>
				<BaseCopyRight :organization="organization" />
				<TheExtraSns
					:sns="sns"
					:rss="rss"
				/>
			</div>

			<transition
				mode="out-in"
				:name="pageAnimation"
				appear
			>
				<router-view
					:organization="organization"
					:information="information"
					:blog="rss"
					:banner="banner"
					:footer="footer"
					:translation="translation"
					:language="language"
					:public-lang="publicLang"
					:theme-lang="themeLang"
					:count="count"
				/>
			</transition>

			<TheBanner
				v-if="showDisplay"
				:banner="banner"
				:public-lang="publicLang"
			/>

			<TheFooter
				v-if="showDisplay"
				:footer="footer"
				:organization="organization"
				:information="information"
				:translation="translation"
				:public-lang="publicLang"
				:theme-lang="themeLang"
				:count="count.footer"
			/>

			<!-- タブレット、スマホのみ表示 -->
			<TheBottom :bottom="bottom" />

			<BaseColor
				v-if="colorDisplay"
				:theme-lang="themeLang"
			/>
		</div>

		<!-- サイト非公開 -->
		<div v-else-if="openType === 0 && statusCode === 200">
			<Closed :public-lang="publicLang" />
		</div>

		<!-- 500エラー -->
		<div v-else-if="statusCode === 500">
			<Error500 :public-lang="publicLang" />
		</div>

		<!-- 503エラー -->
		<div v-else-if="statusCode === 503">
			<Error503 :public-lang="publicLang" />
		</div>

		<!-- プレビュー -->
		<div v-if="preview">
			<BasePreview :public-lang="publicLang" />
		</div>

		<!-- モーダル用オーバレイ -->
		<div class="iv2-modal-overlay hide" onclick="downModal()"></div>
	</div>
</template>

<script>
import Error500 from './components/systems/Error500.vue';
import Error503 from './components/systems/Error503.vue';
import Closed from './components/systems/Closed.vue';
import TheGlobal from './components/global/TheGlobal.vue';
import TheExtraSns from './components/global/TheExtraSns.vue';
import TheBanner from './components/global/TheBanner.vue';
import TheFooter from './components/global/TheFooter.vue';
import TheBottom from './components/global/TheBottom.vue';
import BaseDrawer from '@/components/items/BaseDrawer.vue';
import BasePreview from '@/components/items/BasePreview.vue';
import BaseCopyRight from '@/components/items/BaseCopyRight.vue';
import InitialSetting from '@/mixins/InitialSetting';
import AddRouter from '@/mixins/AddRouter';
import BaseColor from '@/components/items/BaseColor.vue';

export default {
	components: {
		Error500,
		Error503,
		Closed,
		TheGlobal,
		TheExtraSns,
		TheBanner,
		TheFooter,
		TheBottom,
		BaseCopyRight,
		BaseDrawer,
		BasePreview,
		BaseColor
	},

	mixins: [InitialSetting, AddRouter],

	data() {
		return {
			pageAnimation: 'fade',
			colorDisplay: false,
			scrollY: 0,
			userAgent: '',
			showDisplay: false
		}
	},

	watch: {
		$route(to, from) {
			if (to.path !== from.path) {
				this.diplayActive();
			}
		}
	},

	created() {
		this.diplayActive();

		// QRコードからのアクセス
		const siteId = document.head.querySelector("[name=sid][content]").content;
		const id = siteId;
		const key = 'qrcode';
		if (process.env.NODE_ENV === 'production') {
			this.environment = 'production';
		} else {
			this.environment = 'dev';
		}
		((window.ivGetParams(key))? window.ivSendActivities(id, {user_agent: navigator.userAgent.toLowerCase(),provide_name: key,provide_id: window.ivGetParams(key)}, this.environment) : '');
		
		// safariのみmp4ファイルをキャッシュから取り除く
		this.userAgent = navigator.userAgent;
		setInterval(() => {
			if ( this.userAgent.indexOf('Safari') != -1 && this.userAgent.indexOf('Chrome') == -1) {
				navigator.serviceWorker.controller.postMessage('updateCache');
				window.location.reload(true);
			}
		}, 60 * 60 * 1000);

		// カラーチェンジャーの表示判定
		const display = document.head.querySelector("[name=color_changer]");
		if (display && display.content == "true") {
			this.colorDisplay = true;
		}

		window.addEventListener("scroll", this.handleScroll);
	},

	methods: {
		handleScroll() {
			this.scrollY = window.scrollY;
		},

		diplayActive() {
			if (this.$route.path == '/') {
				this.showDisplay = false;
			} else {
				this.showDisplay = true;
			}
		}
	}
}
</script>

<style lang="scss"></style>