<template>
	<section>
		<div class="iv2-hero">
			<div
				v-if="items.main_visual_type == 'slider'"
				:class="displayType"
				class="iv2-hero-slider position-relative overflow-hidden"
			>
				<div
					v-for="media in items.main_visuals"
					:key="media.id"
					class="iv2-hero-content"
				>
					<transition
						name="slider-fade"
						appear
					>
						<div
							v-if="currentSlide == media.sort_key"
							class="iv2-hero-inner"
						>
							<picture>
								<source
									type="image/webp"
									:srcset="media.file_url + publicLang.general.image_webp"
								>
								<img
									class="iv2-hero-image"
									:src="media.file_url"
									:alt="organization.name + publicLang.general.hero_alt"
								>
							</picture>
							<div
								:class="{ 'border-0': displayType == 'responsive' }"
								class="iv2-hero--color theme-f__hero-frame"
							>
								<a
									v-if="media.use_link === 1 && media.is_external === 0"
									:href="media.link_href"
									target="_self"
									class="iv2-hero-link d-block w-100 h-100"
								></a>
								<a
									v-else-if="media.use_link === 1 && media.is_external === 1"
									:href="media.link_href"
									target="_blank"
									class="iv2-hero-link d-block w-100 h-100"
								></a>
							</div>
							<div class="iv2-hero-catchphrase position-absolute text-center">
								<a
									v-if="media.use_link === 1 && media.is_external === 0"
									:href="media.link_href"
									target="_self"
									class="iv2-hero-link position-absolute d-block w-100 h-100"
								></a>
								<a
									v-if="media.use_link === 1 && media.is_external === 1"
									:href="media.link_href"
									target="_blank"
									class="iv2-hero-link position-absolute d-block w-100 h-100"
								></a>
								<div class="row">
									<picture
										v-if="media.catchphrase_type == 'img' && media.catchphrase_image_url"
										class="iv2-hero-catchphrase__picture text-center m-auto"
									>
										<source
											type="image/webp"
											:srcset="media.catchphrase_image_url + publicLang.general.image_webp"
										>
										<img
											:src="media.catchphrase_image_url"
											:alt="organization.name + publicLang.general.catchphrase_alt"
											class="iv2-hero-catchphrase__img"
										>
									</picture>
									<div class="iv2-hero-catchphrase__text col-md-12 mx-auto text-center">
										<h2
											v-if="media.catchphrase_type == 'text' && media.catchphrase_text"
											class="iv2-hero__main-title text-pre"
										>{{ media.catchphrase_text }}</h2>
										<h3
											v-if="media.summary_text"
											class="iv2-hero__sub-title text-pre"
										>{{ media.summary_text }}</h3>
									</div>
								</div>
							</div>
						</div>
					</transition>
				</div>
			</div>
			<div
				v-else-if="items.main_visual_type == 'movie'"
				:class="displayType"
				class="iv2-hero-video overflow-hidden position-relative"
			>
				<video
					class="iv2-hero-content fit-img"
					autoplay
					muted
					loop
					playsinline
				>
					<source
						:src="data.file_url"
						type="video/mp4"
					>
				</video>
				<div
					:class="{ 'border-0': displayType == 'responsive' }"
					class="iv2-hero--color theme-f__hero-frame"
				></div>
				<div class="iv2-hero-catchphrase position-absolute text-center">
					<div class="row">
						<picture
							v-if="data.catchphrase_type == 'img' && data.catchphrase_image_url"
							class="iv2-hero-catchphrase__picture text-center m-auto"
						>
							<source
								type="image/webp"
								:srcset="data.catchphrase_image_url + publicLang.general.image_webp"
							>
							<img
								:src="data.catchphrase_image_url"
								:alt="organization.name + publicLang.general.catchphrase_alt"
								class="iv2-hero-catchphrase__img"
							>
						</picture>
						<div class="iv2-hero-catchphrase__text col-md-12 mx-auto text-center">
							<h2
								v-if="data.catchphrase_type == 'text' && data.catchphrase_text"
								class="iv2-hero__main-title text-pre"
							>{{ data.catchphrase_text }}</h2>
							<h3
								v-if="data.summary_text"
								class="iv2-hero__sub-title text-pre"
							>{{ data.summary_text }}</h3>
						</div>
					</div>
					<div
						v-if="data.use_button == 1"
						class="mt-2 text-center"
					>
						<span>
							<a
								:href="data.link_href"
								:target="windowSelected"
								class="iv2-hero-btn theme__main-btn is-btn is-btn-ghost2 size-16"
							>{{ data.button_label }}</a>
						</span>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	props: {
		items: Object,
		organization: Object,
		publicLang: Object,
		showDisplay: String,
		responsive: Boolean
	},

	data() {
		return {
			currentSlide: 1,
			windowSelected: '',
			viewMore: 'pattern',
			height: '',
			data:{}
		}
	},

	created() {
		if (this.items.main_visual_type == 'movie') {
			this.data = this.items.main_visuals[0];
			if (this.data.is_external == 1) {
				this.windowSelected = '_blank';
			} else {
				this.windowSelected = '_self';
			}
		}

		this.displayType = this.items.display_type;
	},

	mounted() {
		if (this.items.main_visual_type == 'slider' && this.items.main_visuals.length > 1) {
			setInterval(() => {
				this.currentSlide = this.currentSlide < this.items.main_visuals.length ? this.currentSlide + 1 : 1;
			}, 6000)
		}

		if (this.responsive === false) {
			this.changeLayout();
		}
	},

	updated() {
		if (this.showDisplay == 'hero' && this.responsive === false) {
			this.changeLayout();
		}
	},

	methods: {
		// 前後のセクションを定義
		changeLayout() {
			this.height = document.getElementsByClassName('iv2-hero')[0].clientHeight;
			this.$emit('view-more', this.viewMore, this.height);
		}
	}
}
</script>

<style lang="scss">
.slider-fade-enter-active,
.slider-fade-leave-active {
	transition: opacity 1s;
}
.slider-fade-enter,
.slider-fade-leave-to {
	opacity: 0;
}

.iv2-hero {
	.full_screen,
	.responsive.iv2-hero-slider {
		height: 100vh;
	}

	.full_screen {
		&.iv2-hero-video {
			.iv2-hero-content {
				height: 100%;
			}
		}
	}

	.full_screen {
		.iv2-hero--color {
			position: fixed;
		}
	}

	.responsive {
		.iv2-hero--color {
			position: absolute;
		}
	}

	.iv2-hero-slider {
		.iv2-hero-content {
			position: absolute;
			width: 100%;
			height: 100%;

			.iv2-hero-inner {
				width: 100%;
				height: 100%;

				.iv2-hero-image {
					margin: 0 !important;
					height: 100%;
					width: 100%;
					object-fit: cover;
				}
			}
		}
	}

	.iv2-hero-video {
		.iv2-hero-content {
			margin: 0 !important;
			width: 100%;
			object-fit: cover;
			display: block;
		}
	}

	.iv2-hero--color {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	.iv2-hero-link {
		cursor: pointer;
		z-index: 21;
	}

	.iv2-hero-catchphrase {
		width: 80%;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		margin: auto;
		color: #ffffff;
		z-index: 11;
		animation-name: fade;
		animation-duration: 3s;
		text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);

		.iv2-hero-catchphrase__picture {
			width: 600px;

			.iv2-hero-catchphrase__img {
				max-height: 300px;
				filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
			}
		}
	}

	.iv2-hero-btn {
		max-width: 100%;
	}

	@keyframes fade {
		0% {
			opacity: 0;
		}
		20% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
}

/* PC */
@media (min-width: 1200px) {
	.iv2-hero {
		.responsive {
			&.iv2-hero-video {
				height: 100vh;

				.iv2-hero-content {
					height: 100%;
				}
			}
		}
		
		.iv2-hero-catchphrase {
			.iv2-hero-catchphrase__text {
				.iv2-hero__main-title {
					font-size: 4.2rem;
				}

				.iv2-hero__sub-title {
					font-size: 1.5rem;
				}
			}
		}
	}
}

/* TABLET Only */
@media (max-width: 1199px) and (min-width: 768px) {
	.iv2-hero {
		.responsive {
			&.iv2-hero-slider {
				height: 50vh;
			}

			.iv2-hero-catchphrase {
				.iv2-hero-catchphrase__picture {
					.iv2-hero-catchphrase__img {
						max-height: 250px;
					}
				}
			}
		}

		.full_screen {
			.iv2-hero--color {
				top: 80px;
				height: calc(100% - 80px);
			}
		}

		.iv2-hero-catchphrase {
			.iv2-hero-catchphrase__text {
				.iv2-hero__main-title {
					font-size: 3rem;
				}
			}
		}
	}
}

/* SP Only */
@media (max-width: 767px) {
	.iv2-hero {
		.full_screen {
			height: calc(100vh - 60px);

			.iv2-hero--color {
				top: 60px;
				height: calc(100% - 120px);
			}

			.iv2-hero-catchphrase {
				.iv2-hero-catchphrase__picture {
					width: 90%;

					.iv2-hero-catchphrase__img {
						max-height: 250px;
					}
				}
				.iv2-hero-catchphrase__text {
					.iv2-hero__main-title {
						font-size: 2rem;
					}

					.iv2-hero__sub-title {
						font-size: 1rem;
					}
				}
			}
		}

		.responsive {
			&.iv2-hero-slider {
				height: 30vh;
			}

			.iv2-hero-catchphrase {
				.iv2-hero-catchphrase__picture {
					width: 80%;

					.iv2-hero-catchphrase__img {
						max-height: 150px;
					}
				}

				.iv2-hero-catchphrase__text,
				.iv2-hero-btn {
					display: none;
				}
			}
		}
	}
}
</style>
