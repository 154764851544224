<template>
    <div>
        <h2 class="mb-1 iv-paragraph">質問数</h2>
        <p class="mb-4 mt-0 iv-paragraph">{{ num }} 問 ( 所要時間：約 {{ time }} 分 )</p>
    </div>
</template>

<script>
export default {
	props: {
		num: Number,
		time: Number
	}
}
</script>

<style lang="scss"></style>