<template>
	<div class="iv2__host-name">
		<span class="iv2__host-name--text theme-f__host-name">{{ organization.copy_right }}</span>
	</div>
</template>

<script>
export default {
	props: {
		organization: Object
	}
}
</script>

<style lang="scss">
.iv2__host-name {
	-webkit-writing-mode: vertical-rl;
	writing-mode: vertical-rl;
	letter-spacing: 4px;
	position: fixed;
	top: 50%;
	transform: translateY(-50%);
	width: 30px;

	.iv2__host-name--text {
		font-size: 10px;
		white-space: nowrap;
	}
}
</style>